import GlobalStyle from "./styles/global";
import styled from "styled-components";
import Form from "./components/autoavaliacao/Form.js";
import Grid from "./components/autoavaliacao/Grid";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import logo from "./styles/imagem/Logomarca Cooperval-02.png"



const Container = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;

  @media (max-width: 1360px) {
    width: 1250px;
  }
  @media (max-width: 1024px) {
    width: 914px;
  }
  @media (max-width: 860px) {
    width: 750px;
  }
  @media (max-width: 640px) {
    width: 530px;
  }
  @media (max-width: 480px) {
    width: 370px;
  }
  @media (max-width: 375px) {
    width: 265px;
  }
`;

const Title = styled.nav`
  height: 6rem;
  background-color: #14141c;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;


`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;

  img{
    padding-left: 33rem;
    padding-top: 1%;
  }

  @media (max-width: 1360px) {
    img{
      padding-left: 20rem;
      padding-top: 1%;
    }
  };
    
  @media (max-width: 1024px) {
    font-size: 24px;
    img{
      padding-left: 10rem;
      padding-top: 1%;
    }
  };

  @media (max-width: 860px) {
    font-size: 24px;
    img{
      display: none;
    }
  };

  @media (max-width: 640px) {
    font-size: 22px;
    img{
      display: none;
    }
  };

  @media (max-width: 480px) {
    display: flex;
    text-align: center;
    font-size: 22px;
    img{
      display: none;
    }
  };

  @media (max-width: 375px) {
    display: flex;
    text-align: center;
    font-size: 18px;
    img{
      display: none;
    }
  };
`;



function Autoavaliacao() {
  const [users, setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [filteredOption, setFilteredOption] = useState("");
  const [funcaoHandleSaveAvaliacao, setHandleSaveAvaliacao] = useState("");
  const [insertedForm, setInserted] = useState("");

  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/avaliacao/tab_pergunta_avaliacao");
      //const res = await axios.get("http://192.168.100.103:8831/avaliacao/tab_pergunta_avaliacao");
      setUsers(res.data.sort((a, b) => (a.id_item_avaliacao > b.id_item_avaliacao ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  };

  const [avaliacao, setAvaliacao] = useState([]);
  const [modulo, setModulo] = useState([]);
  const [pergunta, setPergunta] = useState([]);
  const [resposta, setResposta] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const avaliacaoResponse = await axios.get("/apinova/avaliacao/tab_sistema_avaliacao/");
        //const avaliacaoResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_sistema_avaliacao/");
        setAvaliacao(avaliacaoResponse.data);

        const moduloResponse = await axios.get("/apinova/avaliacao/tab_item_sistema_avaliacao/");
        //const moduloResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_item_sistema_avaliacao/");
        setModulo(moduloResponse.data);

        const perguntaResponse = await axios.get("/apinova/avaliacao/tab_pergunta_avaliacao/");
        //const perguntaResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_pergunta_avaliacao/");
        setPergunta(perguntaResponse.data);

        const respostaResponse = await axios.get("/apinova/avaliacao/tab_faixa_avaliacao/");
        //const respostaResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_faixa_avaliacao/");
        setResposta(respostaResponse.data);

      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };

    fetchData(); 
    getUsers();
    
  }, []);

 




  
  useEffect(() => {
    const currentUrl = window.location.href;
    
    if (currentUrl.includes('?login=')) {
     
      const loginEncoded = currentUrl.split('?login=')[1];
      const logon = atob(decodeURIComponent(loginEncoded));

      if (!logon) {
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }
  }, []);


  return (
    <>

      <Container>
        <Title>
          <Title2>
            <h2>Autoavaliação por competências</h2>
            <img src={logo} alt="Logo" style={{ width: '180px' }}/>
          </Title2>
        </Title>
        <Form onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} setFilteredOption={setFilteredOption} setHandleSaveAvaliacao={setHandleSaveAvaliacao} setInserted={setInserted}/>
        <Grid setOnEdit={setOnEdit} users={users} setUsers={setUsers} filteredOption={filteredOption} avaliacao={avaliacao} modulo={modulo} pergunta={pergunta} resposta={resposta} funcaoHandleSaveAvaliacao={funcaoHandleSaveAvaliacao} insertedForm={insertedForm}/>
      
      
      </Container>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Autoavaliacao;
