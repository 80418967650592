import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Form from "./components/trocarSenha/Form.js";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import GlobalStyle from "./styles/global";
import logo from "./components/login/Logomarca Cooperval-02.png"




const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 460px;
  margin-top:25%;
  height: auto;

  @media (max-width: 1024px) {
    width: 460px;
  }
  @media (max-width: 840px) {
    width: 460px;
  }
  @media (max-width: 640px) {
    width: 460px;
  }
  @media (max-width: 500px) {
    width: 400px;
    padding-left: 20px;
  }
  @media (max-width: 420px) {
    width: 320px;
    padding-left: 1px;
    margin: auto;
    Form{
      margin-top: 50%;
    }
    
  }
`;

const Title = styled.nav`
  height: 6rem;
  width: 460px;
  background-color: #0D7E48;
  border-radius: 0.2rem;

  @media (max-width: 1024px) {
    height: 6rem;
    width: 480px;
  }
  @media (max-width: 840px) {
    height: 5rem;
    width: 480px;
  }

  @media (max-width: 640px) {
    height: 5rem;
    width: 480px;
  }
  @media (max-width: 500px) {
    height: 5rem;
    width: 460px;
  }
  @media (max-width: 420px) {
    height: 4rem;
    width: 450px;
  }
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;

  @media (max-width: 1024px) {
    font-size: 25px;
  }
  @media (max-width: 840px) {
    font-size: 22px;
  }
  @media (max-width: 640px) {
    font-size: 22px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 420px) {
    font-size: 20px;
  }  
`;











function TrocarSenha() {
  const [setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);



  const getUsers = useCallback(async () => {
    try {
      //const res = await axios.get("http://192.168.100.103:8831/trocarsenha/");
      const res = await axios.get("/apinova/trocarsenha/");
      setUsers(res.data.sort((a, b) => (a.logon > b.logon ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Container>
        <Title>
          <Title2>
            <h2>DHO</h2>
            <img src={logo} style={{ width: '200px', height: 'auto' }} alt="Logo"/>
          </Title2>
        </Title>
        <Form onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} />
      </Container>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default TrocarSenha;
