import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";



const Table = styled.table`
  width: 1200px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 20px auto;
  word-break: break-all;

  @media (max-width: 1024px) {
    width: 900px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 860px) {
    width: 740px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 520px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 480px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px; 
  }
  @media (max-width: 375px) {
    width: 300px;
    padding: 5px;
    margin: 5px 20px 10px; 
  }
  
`;
export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  @media (max-width: 640px) {
    Tr {
      display: flex;
      flex-direction: column;
    }
    
    Td4,
    Td5 {
      width: 100%; 
    }
  }

`;
export const Tr = styled.tr`
  text-align: center;
  padding-bottom: 25px;
  
  @media (max-width: 640px) {
    width: 488px;
  }
  @media (max-width: 480px) {
    width: 335px;
  }
  @media (max-width: 375px) {
    width: 270px;
  }
`;
export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;
export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
  
  select{
    width: 200px;
    height: 40px;
  }
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 500px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
export const Td1 = styled.td`
  background: #D9D9D9;
  padding:10px;
  word-wrap: break-word;
  word-break: break-word;
`;
export const Td4 = styled.td`
  padding-top: 15px;
  text-align: center;
  
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
  
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
`;


export const Td5 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
export const Td2 = styled.td`
  padding-top: 15px;
  text-align: center;
  display: none; /* Inicialmente oculta */
  
  @media (min-width: 3000px) {
    display: ${(props) => (props.onlyWeb2 ? "table-cell" : "none")};
  }
`;
export const Td3 = styled.td`
  padding-top: 15px;
  text-align: center;
  display: none; /* Inicialmente oculta */

  @media (min-width: 3000px) {
    /* Define o display apenas quando a largura for maior que 640px */
    display: ${(props) => (props.onlyWeb2 ? "table-cell" : "none")};
  }
`;
const CreateButton = styled.a`
  display: inline-block;
  bottom: 10px; 
  right: 20px; 
  z-index: 999; 
  padding: 10px 20px;
  background-color: #808080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  
  margin: 1% 45% 3%;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 1024px) {
    margin: 1% 29%;

  }
  @media (max-width: 860px) {
    margin: 0.1% 40%;

  }
  @media (max-width: 640px) {
    margin: 0.1% 35%;

  }
  @media (max-width: 480px) {
    font-size: 18px;
    margin: 1% 30%;
  }
  @media (max-width: 375px) {
    font-size: 18px;
    margin: 1% 25%;
  }
`;

const Grid = ({ users, filteredOption, avaliacao, modulo, pergunta, resposta, funcaoHandleSaveAvaliacao, insertedForm }) => {
  const [respostas, setRespostas] = useState([]);
  const [isVisible, setIsVisible] = useState(!!filteredOption);
  console.log(modulo, pergunta, resposta, avaliacao);


  const urlParams = new URLSearchParams(window.location.search);
  const queryString = urlParams.get('login');
  let logon = null;
  if (queryString) {
    const loginValue = queryString.split('&')[0];
    const loginDecoded = decodeURIComponent(loginValue);
    logon = atob(loginDecoded);
    //console.log("Login:", logon);
  } else {
    console.log("Login não encontrado na URL.");
  }
  const loginEncoded = encodeURIComponent(btoa(logon));
  //console.log(loginEncoded);



  
  useEffect(() => {
    setIsVisible(!!filteredOption);
  }, [filteredOption]);



  const descricaoModulo = (id) => {
    const itemEncontrado = modulo.find((item) => item.id_item_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoPergunta = (id) => {
    const itemEncontrado = pergunta.find((item) => item.id_item_pergunta === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };






  const handleSaveAvaliacao = async () => {
    try {
      const avaliacaoFiltrada = pergunta.filter(item => item.id_avaliacao == filteredOption);

      const id_aplicacaoavaliacao = insertedForm;
      const id_avaliacao = filteredOption;

      for (const avaliacaoItem of avaliacaoFiltrada) {
        //const response2 = await axios.post('http://192.168.100.103:8831/autoavaliacao/tab_resultado_aplicacao-form/', {
        const response2 = await axios.post('/apinova/autoavaliacao/tab_resultado_aplicacao-form/', {
          id_aplicacaoavaliacao,
          id_avaliacao,
          id_item_avaliacao: avaliacaoItem.id_item_avaliacao,
          id_item_pergunta: avaliacaoItem.id_item_pergunta,
          id_faixa: "Escolha uma opção"
        });
        
      }
    } catch (error) {
      console.error('Erro ao enviar dados para o servidor:', error);
    }
  }; 


  useEffect(() => {
    setTimeout(() => {
      handleSaveAvaliacao(); 
    }, 2000);
  }, [funcaoHandleSaveAvaliacao]);


  const handleSaveItem = async (respostaAtualizada) => {
    try {
      const id_aplicacaoavaliacao = insertedForm;
      const id_avaliacao = Number(filteredOption); 
      const id_faixa = Number(respostaAtualizada.id_faixa); 
      //const response = await axios.put('http://192.168.100.103:8831/avaliacao/tab_resultado_aplicacao-form-update/' + id_aplicacaoavaliacao, {
      const response = await axios.put('/apinova/avaliacao/tab_resultado_aplicacao-form-update/' + id_aplicacaoavaliacao, {
        id_avaliacao,
        id_item_avaliacao: respostaAtualizada.id_item_avaliacao,
        id_item_pergunta: respostaAtualizada.id_item_pergunta,
        id_faixa,
      });

        
      
    } catch (error) {
      
    }
  };
  


  const getResultado = async () => {
    try {
    //const res = await axios.get(`http://192.168.100.103:8831/avaliacao/tab_resultado`);
      const res = await axios.get(`/apinova/avaliacao/tab_resultado`);
      const resultadoFiltrado = res.data.filter(item => item.id_aplicacaoavaliacao == insertedForm);
      
      const temNull = resultadoFiltrado.some(item => item.id_faixa === "Escolha uma opção");
      
      if (temNull) {
        toast.warn("Verifique se todas as perguntas foram respondidas!");
      } else {
         
        handleDirecionar();
      }
  
    } catch (error) {
      toast.error(error);
    }
  };
  
  
  const handleSaveFinalizar = async (respostaAtualizada) => {
    try {
      const id_aplicacaoavaliacao = insertedForm;
      const response2 = await axios.put('/apinova/autoavaliacao/finalizar/' + id_aplicacaoavaliacao, {
      //const response2 = await axios.put('http://192.168.100.103:8831/autoavaliacao/finalizar/' + id_aplicacaoavaliacao, {
        finalizado: "Sim"
      });

      //console.log('Dados enviados para o servidor com sucesso:', response);
    } catch (error) {
      //console.error('Erro ao enviar dados para o servidor:', error);
    }
  };
   
  const handleDirecionar = async () => {
    handleSaveFinalizar();
    setTimeout(() => {
     window.location.href = `/?login=${loginEncoded}`;
    }, 2000);

    toast.success("Avaliação finalizada com sucesso!");
  };

  return (
    <div >
      {isVisible && (
        <Table>
          <Tbody>
            {users
            .filter((user) => user.id_avaliacao == filteredOption)
            .reduce((acc, item) => {
              const existingGroup = acc.find((group) => group.id_item_avaliacao === item.id_item_avaliacao);

              if (existingGroup) {
                existingGroup.id_item_perguntas.push(item.id_item_pergunta);
              } else {
                acc.push({
                  id_item_avaliacao: item.id_item_avaliacao,
                  id_item_perguntas: [item.id_item_pergunta],
                });
              }

              return acc;
            }, []).map((group, i) => (
              <React.Fragment key={i}>

                <Tr>
                  <Td1 width="100%" colSpan={2}>
                    {descricaoModulo(group.id_item_avaliacao)}
                  </Td1>
                </Tr>

                {/* Linha com descricaoPergunta e o primeiro select */}
                <Tr>
                  <Td width="auto">{descricaoPergunta(group.id_item_perguntas[0])}</Td>
                  <Td width="auto">
                    <select
                      value={respostas.find((resposta) => resposta.id_item_pergunta === group.id_item_perguntas[0])?.id_faixa || ''}
                      onChange={(e) => {
                        const respostaAtualizada = {
                          id_item_avaliacao: group.id_item_avaliacao,
                          id_item_pergunta: group.id_item_perguntas[0],
                          id_faixa: e.target.value,
                        };
                        setRespostas((prevRespostas) => [...prevRespostas.filter((res) => res.id_item_pergunta !== group.id_item_perguntas[0]), respostaAtualizada]);
                        handleSaveItem(respostaAtualizada);
                      }}
                    >
                      <option value="" disabled hidden>
                        Escolha uma opção
                      </option>
                      {resposta
                        .filter((res) => res.id_item_pergunta === group.id_item_perguntas[0])
                        .map((opcao, index) => (
                          <option key={index} value={opcao.id_faixa}>
                            {opcao.descricao}
                          </option>
                        ))}
                    </select>
                  </Td>
                </Tr>


                {group.id_item_perguntas.slice(1).map((id_item_pergunta, j) => (
                  <Tr key={`${i}_${j}`}>
                    <Td width="auto">{descricaoPergunta(id_item_pergunta)}</Td>
                    <Td width="auto">
                      <select
                        value={respostas.find((resposta) => resposta.id_item_pergunta === id_item_pergunta)?.id_faixa || ''}
                        onChange={(e) => {
                          const respostaAtualizada = {
                            id_item_avaliacao: group.id_item_avaliacao,
                            id_item_pergunta: id_item_pergunta,
                            id_faixa: e.target.value,
                          };
                          setRespostas((prevRespostas) => [...prevRespostas.filter((res) => res.id_item_pergunta !== id_item_pergunta), respostaAtualizada]);
                          handleSaveItem(respostaAtualizada);
                        }}
                      >
                        <option value="" disabled hidden>
                          Escolha uma opção
                        </option>
                        {resposta
                          .filter((res) => res.id_item_pergunta === id_item_pergunta)
                          .map((opcao, index) => (
                            <option key={index} value={opcao.id_faixa}>
                              {opcao.descricao}
                            </option>
                          ))}
                      </select>
                    </Td>
                  </Tr>
                ))}
              </React.Fragment>
            ))}
          </Tbody>
        </Table>

      )}{isVisible && (
        <CreateButton onClick={getResultado}>
          Finalizar avaliação!
        </CreateButton>
      )}
    </div>


  );
};


export default Grid;
