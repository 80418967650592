import React, { useState } from "react";
import styled from "styled-components";
import Grid from "./components/relatorio/Grid";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import GlobalStyle from "./styles/global";
import logo from "./styles/imagem/Logomarca Cooperval-02.png";
import { FaHome } from "react-icons/fa";
import { ImExit, ImCross } from "react-icons/im";

const Container = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;

  @media (max-width: 1360px) {
    width: 1250px;
  }
`;

const Title = styled.nav`
  height: 6rem;
  background-color: #14141c;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;

  img{
    padding-left: 46rem;
    padding-top: 1%;
  }

  @media (max-width: 1360px) {
    img{
      padding-left: 50rem;
      padding-top: 1%;
    }
  };
    
`;

const Div2 = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;

const ButtonDashboard = styled.button`
  
  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;
const ButtonExit = styled.button`


  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

function Relatorio() {
  const [users, setUsers] = useState([]);
  const [loginEncoded, setLoginEncoded] = useState();

  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/relatorio/");
      //const res = await axios.get("http://192.168.100.103:8831/relatorio/");

      setUsers(res.data.sort((a, b) => {
        if (a.id_aplicacaoavaliacao === b.id_aplicacaoavaliacao) {
          return a.id_item_avaliacao - b.id_item_avaliacao;
        }
        return a.id_aplicacaoavaliacao - b.id_aplicacaoavaliacao;
      }));

      //console.log('Valor de users após a requisiçãoApp:', users);
    } catch (error) {
      toast.error(error.message);
    }
  };

  

  useEffect(() => {
    // Obter a URL atual
    const currentUrl = window.location.href;

    // Verificar se a URL contém o parâmetro 'login'
    if (currentUrl.includes('?login=')) {
      // Extrair o valor do parâmetro 'login' da URL
      const loginEncoded = currentUrl.split('?login=')[1];
      setLoginEncoded(loginEncoded)
      // Decodificar o valor do parâmetro 'login'
      const logon = atob(decodeURIComponent(loginEncoded));

      //console.log('useEffect', logon);

      // Verificar se logon está presente
      if (!logon) {
        // Redirecionar para a página de login em .php (substitua pela URL correta)
        window.location.href = '/login';
      }
    } else {
      // Se o parâmetro 'login' não estiver presente na URL, redirecionar para a página de login
      window.location.href = '/login';
    }


  }, []);
  






  useEffect(() => {
    getUsers();
  });

  


  const [avaliacao, setAvaliacao] = useState([]);
  const [modulo, setModulo] = useState([]);
  const [pergunta, setPergunta] = useState([]);
  const [resposta, setResposta] = useState([]);
  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [avaliacaoResponse, moduloResponse, perguntaResponse, respostaResponse, usuarioResponse] = await Promise.all([
          axios.get("/apinova/avaliacao/tab_sistema_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_item_sistema_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_pergunta_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_faixa_avaliacao/"),
          axios.get("/apinova/usuarios/"),
          //axios.get("http://192.168.100.103:8831/avaliacao/tab_sistema_avaliacao/"),
          //axios.get("http://192.168.100.103:8831/avaliacao/tab_item_sistema_avaliacao/"),
          //axios.get("http://192.168.100.103:8831/avaliacao/tab_pergunta_avaliacao/"),
          //axios.get("http://192.168.100.103:8831/avaliacao/tab_faixa_avaliacao/"),
          //axios.get("http://192.168.100.103:8831/usuarios/"),
        ]);
  
        setAvaliacao(avaliacaoResponse.data);
        setModulo(moduloResponse.data);
        setPergunta(perguntaResponse.data);
        setResposta(respostaResponse.data);
        setUsuario(usuarioResponse.data);
  
      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };
  
    fetchData();
    getUsers();
  }, []);

  const handleDashboard = () => {
    const url = `/?login=${loginEncoded}`;
    window.location.href = url;
  };

  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };

  return (
    <>
      <Container>
        <Title>
          <Title2>
            <h2>Relatório das Avaliações</h2>
            <img src={logo} style={{ width: '180px' }} alt="Logo" />
          </Title2>
        </Title>

        <Grid users={users} avaliacao={avaliacao} modulo={modulo} pergunta={pergunta} resposta={resposta} usuario={usuario}/>
      </Container>
      <Div2>
          <ButtonDashboard onClick={handleDashboard} title={"Voltar a tela anterior"}>
            <FaHome title={"Voltar a tela anterior"} />
          </ButtonDashboard>
          <ButtonExit onClick={handleExit} title={"Logout, Sair"}>
            <ImExit title={"Logout, Sair"} />
          </ButtonExit>

        </Div2>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Relatorio;
