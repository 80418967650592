import React, { useState } from 'react';
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const FormContainer = styled.form`
  width: 100%;
  max-width: 440px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: center; 

  display: flex; 
  padding: 5px 10px;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 1px;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 840px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 500px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 420px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;


const Container = styled.div`
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinhe os itens à esquerda */
  text-align: start;
  gap: 10px;
  padding: 0 10px;
  height: auto;



  @media (max-width: 1024px) {
    width: 500px;
  }
  @media (max-width: 840px) {
    width: 460px;
  }
  @media (max-width: 640px) {
    width: 440px;
  }
  @media (max-width: 500px) {
    width: 350px;
  }
`;


const Title2 = styled.h2`
  color: #4f4f4f;
  padding-top: 2vw;

 

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    padding-left: 25%;
  }
  @media (max-width: 840px) {

    display: flex;
    padding-left: 10%;
  }
  @media (max-width: 640px) {
    font-size: 22px;
    display: flex;
    padding-left: 10%;
  }
  @media (max-width: 500px) {
    font-size: 22px;
    display: flex;
    padding-left: 20%;


  }
  @media (max-width: 420px) {
    font-size: 20px;
    display: flex;
    padding-left: 20%;

  }

`;


const Input = styled.input`
  width: 400px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 840px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 400px;
    height: 37px;
  }
  @media (max-width: 500px) {
    width: 330px;
    height: 37px;
  }
`;


const Label = styled.label`
  padding-top: 25px;
  font-weight: bold;
  font-size: 17px;
  text-align: left
  color: #808080;
  p{
    color: #4f4f4f;
  }
  
  @media (max-width: 500px) {
  font-size: 20px;
  }
`;


const ContainerButton = styled.div`
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  width: 400px;

`;


const Button = styled.button`
  padding: 10px;
  margin: 20px 0px 20px 10px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color: #7A7A7A;
  color: white;
  width: 120px;
  height: 40px;
  font-weight: bold;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
  @media (max-width: 840px) {
    height: 50px;
    width: 120px;
  }
  @media (max-width: 640px) {
    height: 50px;
    width: 120px;
  }
  @media (max-width: 420px) {
    height: 50px; 
    width: 120px;
  }
`;



function TrocarSenha() {
  const [logon, setLogon] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');


  const validateNewPassword = (password) => {
    // Defina seus critérios de validação da senha aqui
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordRegex.test(password);
  };
  
  const handleTrocarSenha = async () => {
    try {
      const logonUpperCase = logon.toUpperCase(); // Converter para maiúsculas
      //console.log('Logon em maiúsculas:', logonUpperCase);
      //console.log('Senha Atual:', currentPassword);
      //console.log('Nova Senha:', newPassword);
      //console.log('Logon:', logon);
  
      // Valide a nova senha
      if (!validateNewPassword(newPassword)) {
        toast.error('Nova senha inválida. Deve conter no mínimo 8 caracteres, incluindo letras e números.');
        return; // Saia da função se a nova senha for inválida
      }
      //const response = await fetch(`http://192.168.100.103:8815/trocarsenha/${logon}`, {
      const response = await fetch(`/apinova/trocarsenha/${logon}`, {
        method: 'PUT', // Use o método PUT para atualizar a senha
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          logon: logonUpperCase,
          currentPassword, // Senha atual
          newPassword, // Nova senha
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success(data.mensagem);
  
        // Limpar campos
        setLogon('');
        setCurrentPassword('');
        setNewPassword('');
  
        const loginEncoded = encodeURIComponent(btoa(logonUpperCase));
        //console.log('Login Encoded:', loginEncoded);
  
        setTimeout(function () {
          //window.location.href = `http://192.168.100.103:3015/?login=${loginEncoded}`;
          window.location.href = `/?login=${loginEncoded}`;
        }, 2000);
      } else {
        toast.error(data.error || 'Erro desconhecido na troca de senha.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro de rede ou erro desconhecido.');
    }
  };

  const handleLogin = () => {
    //const url = `http://192.168.100.103:3015/login`;
    const url = `/login`;
    window.location.href = url;
  };


  return (
    <FormContainer>
      <Title2>

        <h4>Avaliação por competências</h4>

      </Title2>
      <Container>
        <Label><p>Login:</p></Label>
        <Input
          type="text"
          placeholder="Digite aqui..."
          value={logon}
          onChange={(e) => setLogon(e.target.value)}
        />
        <Label><p>Senha:</p></Label>
        <Input
          type="password"
          placeholder="Digite aqui..."
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <Label><p>Nova senha:</p></Label>
        <Input
          type="password"
          placeholder="Digite aqui..."
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <ContainerButton>
        <Button type="button" onClick={handleTrocarSenha}>
          Alterar
        </Button>
          <Button type="button" onClick={handleLogin}>
            Login
          </Button>
        </ContainerButton>

      </Container>
    </FormContainer>
  );
}

export default TrocarSenha;
