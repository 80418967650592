import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";



const Table = styled.table`
  width: 1200px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 20px auto;
  word-break: break-all;

  @media (max-width: 1024px) {
    width: 900px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 860px) {
    width: 740px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 520px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 480px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px; 
  }
  @media (max-width: 375px) {
    width: 300px;
    padding: 5px;
    margin: 5px 20px 10px; 
  }
  
`;
export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  @media (max-width: 640px) {
    Tr {
      display: flex;
      flex-direction: column;
    }
    
    Td4,
    Td5 {
      width: 100%; 
    }
  }

`;
export const Tr = styled.tr`
  text-align: center;
  padding-bottom: 25px;
  
  @media (max-width: 640px) {
    width: 488px;
  }
  @media (max-width: 480px) {
    width: 335px;
  }
  @media (max-width: 375px) {
    width: 270px;
  }
`;
export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;
export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
  
  select{
    width: 200px;
    height: 40px;
  }
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 500px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
export const Td1 = styled.td`
  background: #D9D9D9;
  padding:10px;
  word-wrap: break-word;
  word-break: break-word;
`;
export const Td4 = styled.td`
  padding-top: 15px;
  text-align: center;
  
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
  
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
`;


export const Td5 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;
export const Td2 = styled.td`
  padding-top: 15px;
  text-align: center;
  display: none; /* Inicialmente oculta */
  
  @media (min-width: 3000px) {
    display: ${(props) => (props.onlyWeb2 ? "table-cell" : "none")};
  }
`;
export const Td3 = styled.td`
  padding-top: 15px;
  text-align: center;
  display: none; /* Inicialmente oculta */

  @media (min-width: 3000px) {
    /* Define o display apenas quando a largura for maior que 640px */
    display: ${(props) => (props.onlyWeb2 ? "table-cell" : "none")};
  }
`;
const CreateButton = styled.a`
  display: inline-block;
  bottom: 10px; 
  right: 20px; 
  z-index: 999; 
  padding: 10px 20px;
  background-color: #808080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  
  margin: 1% 45% 3%;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 1024px) {
    margin: 1% 29%;

  }
  @media (max-width: 860px) {
    margin: 0.1% 40%;

  }
  @media (max-width: 640px) {
    margin: 0.1% 35%;

  }
  @media (max-width: 480px) {
    font-size: 18px;
    margin: 1% 30%;
  }
  @media (max-width: 375px) {
    font-size: 18px;
    margin: 1% 25%;
  }
`;

const Grid = ({ modulo, pergunta, resposta, itemToContinuar, logonDashboard, adminDashboard }) => {
  console.log("teste", itemToContinuar);
  //console.log("teste1", logonDashboard);
  //console.log("teste2", adminDashboard);
  //console.log("modulo", modulo);
  //console.log("pergunta", pergunta);
  //console.log("Resposta", resposta)

  const filteredOption = itemToContinuar.id_aplicacaoavaliacao;
  const idAvaliacao = itemToContinuar.id_avaliacao;

  const [isVisible, setIsVisible] = useState(!!filteredOption);
  useEffect(() => {
    setIsVisible(!!filteredOption);
  }, [filteredOption]);


  let logon = logonDashboard;
  const loginEncoded = encodeURIComponent(btoa(logon));



  const [users, setUsers] = useState([]);
  
  
  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/autoavaliacao/tab_resultado");
      //const res = await axios.get("http://192.168.100.103:8831/autoavaliacao/tab_resultado");
  
      // Filtra os itens com base no id_aplicacaoavaliacao desejado
      const filteredUsers = res.data.filter(item => item.id_aplicacaoavaliacao === filteredOption);
  
      // Ordena os itens filtrados com base no id_item_avaliacao
      const sortedUsers = filteredUsers.sort((a, b) => (a.id_item_avaliacao > b.id_item_avaliacao ? 1 : -1));
  
      setUsers(sortedUsers);
    } catch (error) {
      toast.error(error);
    }
  };
  

  useEffect(() => {
    getUsers();
  }, []);



  const descricaoModulo = (id) => {
    const itemEncontrado = modulo.find((item) => item.id_item_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoPergunta = (id) => {
    const itemEncontrado = pergunta.find((item) => item.id_item_pergunta === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoResposta = (id) => {

    const itemEncontrado = resposta.find((item) => item.id_faixa == id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const [respostas, setRespostas] = useState([]);
  
  const handleSaveItem = async (respostaAtualizada) => {
    try {
      const id_aplicacaoavaliacao = filteredOption;
      const id_avaliacao = Number(idAvaliacao);
      const id_faixa = Number(respostaAtualizada.id_faixa);

      //const response = await axios.put('http://192.168.100.103:8831/avaliacao/tab_resultado_aplicacao-form-update/' + id_aplicacaoavaliacao, {
      const response = await axios.put('/apinova/avaliacao/tab_resultado_aplicacao-form-update/' + id_aplicacaoavaliacao, {
        id_avaliacao,
        id_item_avaliacao: respostaAtualizada.id_item_avaliacao,
        id_item_pergunta: respostaAtualizada.id_item_pergunta,
        id_faixa,
      });

      setUsers((prevUsers) => {
        const updatedUsers = [...prevUsers];
        const userIndex = updatedUsers.findIndex((user) => user.id_item_pergunta === respostaAtualizada.id_item_pergunta);
        if (userIndex !== -1) {
          updatedUsers[userIndex].id_faixa = respostaAtualizada.id_faixa;
        }
        return updatedUsers;
      });
      //console.log("Inserindo reposta no mysql",response)
    } catch (error) {
    }
  };

  const getResultado = async () => {
    try {
      //const res = await axios.get(`http://192.168.100.103:8831/autoavaliacao/tab_resultado`);
      const res = await axios.get(`/apinova/autoavaliacao/tab_resultado`);
      const resultadoFiltrado = res.data.filter(item => item.id_aplicacaoavaliacao == filteredOption);

      // Verificar se pelo menos um elemento do array tem id_faixa igual a null
      const temNull = resultadoFiltrado.some(item => item.id_faixa === "Escolha uma opção");

      if (temNull) {
        toast.warn("Verifique se todas as perguntas foram respondidas!");
      } else {
        // Se desejar, você pode acessar os dados filtrados
        //console.log(resultadoFiltrado[0]?.id_faixa);


        handleDirecionar();
      }

    } catch (error) {
      toast.error(error);
    }
  };


  const handleSaveFinalizar = async (respostaAtualizada) => {
    try {
      const id_aplicacaoavaliacao = filteredOption;
      //const response2 = await axios.put('http://192.168.100.103:8831/autoavaliacao/finalizar/' + id_aplicacaoavaliacao, {
      const response2 = await axios.put('/apinova/autoavaliacao/finalizar/' + id_aplicacaoavaliacao, {
        finalizado: "Sim"
      });

      //console.log('Dados enviados para o servidor com sucesso:', response);
    } catch (error) {
      //console.error('Erro ao enviar dados para o servidor:', error);
    }
  };


  const handleDirecionar = async () => {
    handleSaveFinalizar();
    setTimeout(() => {
      window.location.href = `/?login=${loginEncoded}`;
    }, 2000);

    toast.success("Avaliação finalizada com sucesso!");
  };


  return (
    <div >
      {isVisible && (
        <Table>
          <Tbody>
            {users
              .filter((users) => users.id_aplicacaoavaliacao == filteredOption)
              .reduce((acc, item) => {
                const existingGroup = acc.find((group) => group.id_item_avaliacao === item.id_item_avaliacao);

                if (existingGroup) {
                  existingGroup.id_item_perguntas.push(item.id_item_pergunta);
                } else {
                  acc.push({
                    id_item_avaliacao: item.id_item_avaliacao,
                    id_item_perguntas: [item.id_item_pergunta],
                  });
                }

                return acc;
              }, []).map((group, i) => (
                <React.Fragment key={i}>

                  <Tr>
                    <Td1 width="100%" colSpan={2}>
                      {descricaoModulo(group.id_item_avaliacao)}
                    </Td1>
                  </Tr>


                  <Tr>
                    <Td width="auto">{descricaoPergunta(group.id_item_perguntas[0])}</Td>
                    <Td width="auto">
                      <select
                        value={
                          respostas.find((resposta) => resposta.id_item_pergunta === group.id_item_perguntas[0])?.id_faixa ||
                          users.find((user) => user.id_item_pergunta === group.id_item_perguntas[0])?.id_faixa ||
                          ''
                        }
                        onChange={(e) => {
                          const respostaAtualizada = {
                            id_item_avaliacao: group.id_item_avaliacao,
                            id_item_pergunta: group.id_item_perguntas[0],
                            id_faixa: e.target.value,
                          };

                          setRespostas((prevRespostas) => [
                            ...prevRespostas.filter((res) => res.id_item_pergunta !== group.id_item_perguntas[0]),
                            respostaAtualizada,
                          ]);

                          handleSaveItem(respostaAtualizada);
                        }}
                      >
                        {(!users.find((user) => user.id_item_pergunta === group.id_item_perguntas[0]) || users.find((user) => user.id_item_pergunta === group.id_item_perguntas[0]).id_faixa === 'Escolha uma opção') &&
                          <option value="Escolha uma opção">
                            Escolha uma opção
                          </option>
                        }

                        {users
                          .filter((user) => user.id_item_pergunta === group.id_item_perguntas[0])
                          .map((opcao, index) => (
                            <option key={index} value={opcao.id_faixa}>
                              {opcao.id_faixa === 'Escolha uma opção' ? '' : descricaoResposta(opcao.id_faixa)}
                            </option>
                          ))}

                        {resposta
                          .filter((res) => res.id_item_pergunta === group.id_item_perguntas[0])
                          .map((opcao, index) => (
                            <option key={index} value={opcao.id_faixa}>
                              {opcao.descricao}
                            </option>
                          ))}
                      </select>
                    </Td>



                  </Tr>
                  {group.id_item_perguntas.slice(1).map((id_item_pergunta, j) => (
                    <Tr key={`${i}_${j}`}>
                      <Td width="auto">{descricaoPergunta(id_item_pergunta)}</Td>
                      <Td width="auto">
                        <select
                          value={
                            (users.find((user) => user.id_item_pergunta === id_item_pergunta)?.id_faixa) ||
                            (respostas.find((resposta) => resposta.id_item_pergunta === id_item_pergunta)?.id_faixa) ||
                            ''
                          }
                          onChange={(e) => {
                            const respostaAtualizada = {
                              id_item_avaliacao: group.id_item_avaliacao,
                              id_item_pergunta: id_item_pergunta,
                              id_faixa: e.target.value,
                            };

                            setRespostas((prevRespostas) => [
                              ...prevRespostas.filter((res) => res.id_item_pergunta !== id_item_pergunta),
                              respostaAtualizada,
                            ]);

                            handleSaveItem(respostaAtualizada);
                          }}
                        >


                          {users
                            .filter((user) => user.id_item_pergunta === id_item_pergunta)
                            .map((opcao, index) => (
                              <option key={index} value={opcao.id_faixa}>
                                {opcao.id_faixa === 'Escolha uma opção' ? 'Escolha uma opção' : descricaoResposta(opcao.id_faixa)}
                              </option>
                            ))}

                          {resposta
                            .filter((res) => res.id_item_pergunta === id_item_pergunta)
                            .map((opcao, index) => (
                              <option key={index} value={opcao.id_faixa}>
                                {opcao.descricao}
                              </option>
                            ))}
                        </select>
                      </Td>




                    </Tr>
                  ))}
                </React.Fragment>
              ))}
          </Tbody>
        </Table>

      )}{isVisible && (
        <CreateButton onClick={getResultado}>
          Finalizar avaliação!
        </CreateButton>
      )}
    </div>


  );
};


export default Grid;
