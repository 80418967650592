import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { FaRegFilePdf } from "react-icons/fa6";
import generatePDF, { Margin } from 'react-to-pdf';

const personalizacao = {
  method: 'open',

  page: {

    margin: Margin.SMALL,

    format: 'A4',

    orientation: 'landscape',
  },
  canvas: {

    mimeType: 'image/png',
    qualityRatio: 1
  },

};
const recuperarConteudoParaPDF = () => document.getElementById('conteudoPDF');
export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Table = styled.table`
  min-width: 80%;
  background-color: #fff;
  box-shadow: 0px 0px 1px #ccc;
  border: 0.5px solid #ccc;
  overflow: auto;
`;

export const Thead = styled.thead`
  
`;

export const Tbody = styled.tbody`
  
`;

export const Tr = styled.tr`
  text-align: center;
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: center;
  padding 5px;
  border: 0.5px solid #ccc;
`;

export const Td = styled.td`
  border: 0.5px solid #ccc;
  padding: 5px ;
  
`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;

  
`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
   

`;

export const Td3 = styled.td`

`;

const ButtonPDF = styled.button`
  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  width: 50px;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.1);
  }
`;




const Grid = ({ itemToVisualize, relatorio }) => {


  const filteredRelatorio = relatorio
  .filter(item => item.id_aplicacaoavaliacao === itemToVisualize.id_aplicacaoavaliacao)
  .sort((a, b) => a.id_avaliacao - b.id_avaliacao ? 1 : -1);

  



  const [avaliacao, setAvaliacao] = useState([]);
  const [modulo, setModulo] = useState([]);
  const [pergunta, setPergunta] = useState([]);
  const [resposta, setResposta] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [avaliacaoResponse, moduloResponse, perguntaResponse, respostaResponse, usuarioResponse] = await Promise.all([
          axios.get("/apinova/avaliacao/tab_sistema_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_item_sistema_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_pergunta_avaliacao/"),
          axios.get("/apinova/avaliacao/tab_faixa_avaliacao/"),
          //axios.get("http://192.168.100.103:8815/avaliacao/tab_sistema_avaliacao/"),
         //axios.get("http://192.168.100.103:8815/avaliacao/tab_item_sistema_avaliacao/"),
          //axios.get("http://192.168.100.103:8815/avaliacao/tab_pergunta_avaliacao/"),
          //axios.get("http://192.168.100.103:8815/avaliacao/tab_faixa_avaliacao/"),
        ]);

        setAvaliacao(avaliacaoResponse.data);
        setModulo(moduloResponse.data);
        setPergunta(perguntaResponse.data);
        setResposta(respostaResponse.data);

      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };

    fetchData();
  }, []);

  const descricaoAvaliacao = (id) => {
    const itemEncontrado = avaliacao.find((item) => item.id_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoModulo = (id) => {
    const itemEncontrado = modulo.find((item) => item.id_item_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoPergunta = (id) => {
    const itemEncontrado = pergunta.find((item) => item.id_item_pergunta === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoResposta = (id) => {
    const faixaId = parseInt(id);
    const itemEncontrado = resposta.find((item) => item.id_faixa === faixaId);
    //console.log('Item encontrado:', itemEncontrado);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };


  return (
    <Div>

      <ButtonPDF onClick={() => generatePDF(recuperarConteudoParaPDF, personalizacao)} >
        <FaRegFilePdf color={"blue"} size={24} />
      </ButtonPDF>

      <div id="conteudoPDF">
        <Table>
          <Thead>
            <Tr>
              <Th>ID:</Th>
              <Th>Data:</Th>
              <Th>Avaliador:</Th>
              <Th>Avaliado:</Th>
              <Th>Avaliação</Th>
              <Th>Modúlo:</Th>
              <Th>Pergunta:</Th>
              <Th>Resposta:</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredRelatorio.map((relatorioItem, index) => (
              <Tr key={index}>
                <Td>{relatorioItem.id_aplicacaoavaliacao}</Td>
                <Td>
                  <div>
                    {new Intl.DateTimeFormat("pt-BR", {
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit"
                    }).format(new Date(relatorioItem.data))}
                  </div>
                </Td>
                <Td>{relatorioItem.logon_avaliador}</Td>
                <Td>{relatorioItem.logon_avaliado}</Td>
                <Td>{descricaoAvaliacao(relatorioItem.id_avaliacao)}</Td>
                <Td>{descricaoModulo(relatorioItem.id_item_avaliacao)}</Td>
                <Td>{descricaoPergunta(relatorioItem.id_item_pergunta)}</Td>
                <Td>{descricaoResposta(relatorioItem.id_faixa)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>


      </div>
    </Div>
  );
};


export default Grid;
