import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './Dashboard.js'
import Login from './Login.js'
import CriandoAvaliacao from './CriandoAvaliacao.js'
import Trocarsenha from './TrocarSenha.js'
import Usuarios from './Usuarios'
import Avaliacao from './Avaliacao'
import Relatorio from './Relatorio'

import Autoavaliacao from "./Autoavaliacao.js";

function App() {
    return (
        <Router >
            
            <Routes >

                <Route exact path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/criando-avaliacao" element={<CriandoAvaliacao />} />
                <Route path="/trocarsenha" element={<Trocarsenha />} />
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/avaliacao" element={<Avaliacao />} />
                <Route path="/relatorio" element={<Relatorio />} />
                <Route path="/autoavaliacao" element={<Autoavaliacao />} />

            </Routes>

        </Router>

    )
}
export default App;