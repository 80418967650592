import GlobalStyle from "./styles/global";
import styled from "styled-components";
import Grid from "./components/dashboard/Grid.js";
import GridAvaliacao from "./components/dashboard/GridAvaliacao.js";
import ContinuarAvaliacao from "./components/dashboard/ContinuarAvaliacao.js";
import VisualizarAvaliacao from "./components/dashboard/VisualizarAvaliacao.js"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./styles/imagem/Logomarca Cooperval-02.png"
import { ImExit } from "react-icons/im";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ImFolderOpen, ImFolder } from "react-icons/im";
import { ImCross } from "react-icons/im";

const Container = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;


  @media (max-width: 1360px) {
    display: flex;
    justify-content: center;
    width: 1250px;
  }
  @media (max-width: 1024px) {
    width: 930px;
  }
  @media (max-width: 860px) {
    width: 740px;
  }
  @media (max-width: 640px) {
    width: 440px;
  }
  @media (max-width: 480px) {
    width: 340px;
  }
  @media (max-width: 376px) {
    width: 360px;
  }
`;
const Div = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;

  @media (max-width: 1024px) {
    Button{
      display: none;
    }
  };
  @media (max-width: 860px) {
    Button{
      display: none;
    }
  };
  @media (max-width: 640px) {
    Button{
      display: none;
    }
  };
  @media (max-width: 480px) {
    width: 450px;
    Button{
      display: none;
    }
  };
  @media (max-width: 376px) {
    width: 350px;
    Button{
      display: none;
    }
  };
`;

const Title = styled.nav`
  height: 6rem;
  background-color: #14141c;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;

  @media (max-width: 860px) {
    width: 148vh;
  };
  @media (max-width: 860px) {
    width: 120vh;
  };
  @media (max-width: 640px) {
    width: 70vh;
  };
  @media (max-width: 480px) {
    width: 53vh;
  };

  @media (max-width: 376px) {
    width: 45vh;
  };
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;

  img{
    padding-left: 55rem;
    padding-top: 1%;
  }

  @media (max-width: 1360px) {
    img{
      padding-left: 40rem;
      padding-top: 1%;
    }
  };
  @media (max-width: 1024px) {
    img{
      padding-left: 25rem;
    }
  };
  @media (max-width: 860px) {
    img{
      padding-left: 15rem;
    }
  };
  @media (max-width: 640px) {
    img{
      display: none;
    }
  };

  @media (max-width: 480px) {
    img{
      display: none;
    }
  };
  
  @media (max-width: 376px) {
    font-size: 20px;
    img{
      display: none;
    }
  };
`;

const ButtonExit = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

const InnerContainer = styled.div`
align-self: flex-start;

@media (max-width: 376px) {
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
};
`;

const P = styled.p`
  text-align: start;
`;
const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
  border: none;
  background-color: #e8eaeb;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 


  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const ButtonModal = styled.button`
  cursor: pointer;
  border: none;
  background-color: #FFF;
  padding: 5px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.5);

  }
`;


const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh; 
  overflow-y: auto; 
`;

function Dashboard() {

  const [adminDashboard, setAdmin] = useState([]);
  const [nameUser, setNameUser] = useState([]);


  const urlParams = new URLSearchParams(window.location.search);
  const loginEncoded = urlParams.get('login');
  const logon = atob(decodeURIComponent(loginEncoded));

  const logonDashboard = logon;



  const [users, setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);

 
  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/relatorio/grid-relatorio");
      //const res = await axios.get("http://192.168.100.103:8831/relatorio/grid-relatorio");
      setUsers(res.data.sort((a, b) => (a.id_aplicacaoavaliacao < b.id_aplicacaoavaliacao ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  };


  useEffect(() => {

    getUsers();
  }, [setOnEdit]);

  





  // Faz um get para verificar se o usuário é admin
  const fetchAdmin = async () => {
    try {
      const response = await axios.get("/apinova/");
      //const response = await axios.get("http://192.168.100.103:8831/");

      const data = response.data;
      const userData = data.find(item => item.logon === logonDashboard);

      if (userData) {
        const adminValue = userData.admin;
        const userName = userData.name;
        setNameUser(userName);
        setAdmin(adminValue);
      
        if (adminValue === "Nao" || adminValue === null) {
          DesativarBotao()
        }
      } else {
       
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('?login=')) {
      
      const loginEncoded = currentUrl.split('?login=')[1];
      const logon = atob(decodeURIComponent(loginEncoded));

      if (!logon) {
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }

    fetchAdmin();
  }, [fetchAdmin]);

  function DesativarBotao() {
    const botoes = document.querySelectorAll('.desativaBotao');
    botoes.forEach((botao) => {
      botao.style.display = 'none';
    });
  }



  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };




  const [avaliacao, setAvaliacao] = useState([]);
  const [relatorio, setRelatorio] = useState([]);

  //const AvaliacaoURL = "http://192.168.100.103:8831/criando-avaliacao/";
  //const RelatorioURL = "http://192.168.100.103:8831/relatorio/";

  const AvaliacaoURL = "/apinova/criando-avaliacao/";
  const RelatorioURL = "/apinova/relatorio/";

  useEffect(() => {
    const fetchAvaliacao = async () => {
      try {
        const res = await axios.get(AvaliacaoURL);
        setAvaliacao(res.data);
      } catch (error) {
        console.error('Erro ao buscar dados de Tipo:', error);
      }
    };
    const fetchRelatorio = async () => {
      try {
        const res = await axios.get(RelatorioURL);
        setRelatorio(res.data.sort((a, b) => (a.id_item_avaliacao < b.id_item_avaliacao ? 1 : -1)));
      } catch (error) {
        console.error('Erro ao buscar dados de Tipo:', error);
      }
    };


    fetchAvaliacao();
    fetchRelatorio();
  }, []);



  const [modulo, setModulo] = useState([]);
  const [pergunta, setPergunta] = useState([]);
  const [resposta, setResposta] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const moduloResponse = await axios.get("/apinova/avaliacao/tab_item_sistema_avaliacao/");
        //const moduloResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_item_sistema_avaliacao/");
        setModulo(moduloResponse.data);

        const perguntaResponse = await axios.get("/apinova/avaliacao/tab_pergunta_avaliacao/");
        //const perguntaResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_pergunta_avaliacao/");
        setPergunta(perguntaResponse.data);

        const respostaResponse = await axios.get("/apinova/avaliacao/tab_faixa_avaliacao/");
        //const respostaResponse = await axios.get("http://192.168.100.103:8831/avaliacao/tab_faixa_avaliacao/");
        setResposta(respostaResponse.data);

      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };

    fetchData();
    getUsers();

  }, []);
  const [mostrarGridAvaliacao, setMostrarGridAvaliacao] = useState(false);
  const handleMostrarGridAvaliacao = () => {
    setMostrarGridAvaliacao(!mostrarGridAvaliacao);
  };

  const [itemToVisualize, setItemToVisualize] = useState(null);
  const [isModalOpenVisualizarAvaliacao, setIsModalOpenVisualizarAvaliacao] = useState(false);
  const ModalVisualizarAvaliacao = (item) => {
    setIsModalOpenVisualizarAvaliacao(!isModalOpenVisualizarAvaliacao);
    setItemToVisualize(item);
  };

  const [itemToContinuar, setItemToContinuar] = useState(null);
  const [isModalOpenContinuarAvaliacao, setIsModalOpenContinuarAvaliacao] = useState(false);
  const ModalContinuarAvaliacao = (item) => {
    setIsModalOpenContinuarAvaliacao(!isModalOpenContinuarAvaliacao);
    setItemToContinuar(item);
  };



  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    const avaliacaoFinalizada = users.some((user) => user.finalizado === "Não" && user.logon_avaliador === logonDashboard);
    
    if(avaliacaoFinalizada === true) {
      setShowMessage(<p>Você possui avaliações não finalizadas.</p>);
    }
    
  }, [users, logonDashboard]);


  
  return (
    <>
      <Container>
        <Title>
          <Title2>
            <h2>Avaliação DHO</h2>
            <img src={logo} style={{ width: '180px' }} alt="Logo" />
          </Title2>
        </Title>
        <InnerContainer>
          <P>Olá {nameUser}, {showMessage}</P>
        </InnerContainer>
        <Grid logonDashboard={logonDashboard}
          adminDashboard={adminDashboard}
        />

        {isModalOpenVisualizarAvaliacao && (
          <ModalContainer>
            <ModalContent>
              <ButtonContainer>
                <ButtonModal onClick={() => {
                  ModalVisualizarAvaliacao();

                }}>
                  {isModalOpenVisualizarAvaliacao ? <ImCross color={"red"} /> : ""}
                </ButtonModal>
              </ButtonContainer>
              <VisualizarAvaliacao
                logonDashboard={logonDashboard}
                adminDashboard={adminDashboard}
                itemToVisualize={itemToVisualize}
                relatorio={relatorio}
                onEdit={onEdit}
              />
            </ModalContent>
          </ModalContainer>
        )}
        {isModalOpenContinuarAvaliacao && (
          <ModalContainer>
            <ModalContent>
              <ButtonContainer>
                <ButtonModal onClick={() => {
                  ModalContinuarAvaliacao();

                }}>
                  {isModalOpenContinuarAvaliacao ? <ImCross color={"red"} /> : ""}
                </ButtonModal>
              </ButtonContainer>
              <ContinuarAvaliacao
                logonDashboard={logonDashboard}
                adminDashboard={adminDashboard}
                itemToContinuar={itemToContinuar}
                modulo={modulo}
                pergunta={pergunta}
                resposta={resposta}
              />
            </ModalContent>
          </ModalContainer>
        )}
        <Div>
          <Button onClick={handleMostrarGridAvaliacao} >
            {mostrarGridAvaliacao ? (
              <>
                <ImFolder color={"red"} size={18} /> Fechar
              </>
            ) : (
              <>
                <ImFolderOpen color={"gold"} size={18} /> Visualizar últimas avaliações
              </>
            )}
          </Button>
        </Div>
        {mostrarGridAvaliacao && (
          <GridAvaliacao
            setOnEdit={setOnEdit}
            users={users}
            setUsers={setUsers}
            logonDashboard={logonDashboard}
            adminDashboard={adminDashboard}
            avaliacao={avaliacao}
            ModalVisualizarAvaliacao={ModalVisualizarAvaliacao}
            ModalContinuarAvaliacao={ModalContinuarAvaliacao}

          />
        )}
      </Container>
      <ButtonExit onClick={handleExit} >
        <ImExit title={"Sair do Avaliação DHO"} />
      </ButtonExit>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Dashboard;





