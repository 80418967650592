import axios from "axios";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";


const FormContainer = styled.form`
  width: auto;
  justify-content: space-between; 
  display: flex; 

  border-radius: 1px;
  align-items: flex-end;

  margin: 10px;


`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;


`;

const Input = styled.input`
  width: 200px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 350px;
    height: 37px;
  }
  @media (max-width: 820px) {
    width: 190px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 270px;
    height: 37px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
`;

const Input2 = styled.input`
  width: 400px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;
  
  @media (max-width: 1024px) {
    width: 500px;
    height: 37px;
  }
  @media (max-width: 820px) {
    width: 190px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 350px;
    height: 37px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
  
`;


const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;


const Button = styled.button`
  font-size: 14px;
  font-weight: bold;

  padding-bottom: 20px;
  cursor: pointer;
  border: none;
  background-color: #FFF;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);

  }
`;

const Div = styled.td`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: flex-end;
    flex-direction: row;
  }
  @media (max-width: 820px) {
    justify-content: flex-end;
    flex-direction: row;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;



const Form = ({ onEdit }) => {

  const ref = useRef();

  useEffect(() => {
    if (onEdit) {

      const user = ref.current;
      user.logon.value = onEdit.logon;
      user.name.value = onEdit.name;
      user.password.value = onEdit.password;
    }
  }, [onEdit]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    const logonValue = user.logon.value;
    const nameValue = user.name.value;
    const passwordValue = user.password.value;




    if (!passwordValue) {
      return toast.warn("Preencha todos os campos!");
    }

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!passwordRegex.test(passwordValue)) {
      return toast.error("Senha deve ter no mínimo 8 caracteres, contendo letras e números.");
    }


    if (onEdit) {


      await axios
        //.put("http://192.168.100.103:8815/usuarios/senha/" + onEdit.logon, {
        .put("/apinova/usuarios/senha/" + onEdit.logon, {
          logon: user.logon.value,
          name: user.name.value,
          password: user.password.value,
        })
        .then(({ data }) => toast.success(data))
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            const details = error.response.data.details;
            const dbError = error.response.data.dbError;
            console.log("Error:", errorMessage);
            console.log("Details:", details);
            console.log("Database Error:", dbError);
            toast.error("Erro ao editar: " + errorMessage);
          }
        });
    }



  };






  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <Div>
          <InputArea>
            <Label>Username:</Label>
            <Input name="logon" type="text" disabled />
          </InputArea>

          <InputArea>
            <Label>Nome:</Label>
            <Input2 name="name" type="text" disabled />
          </InputArea>
        </Div>
        <Div>
          <InputArea>
            <Label>Digite a nova Senha:</Label>
            <Input name="password" type="password" />
          </InputArea>


        </Div>

        <Button title="Salvar senha" type="submit">
          <ImCheckmark color={'#03A64A'} /> Salvar
        </Button>

      </FormContainer>

    </div>

  );
};

export default Form;


