import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "./components/usuarios/Form.js";
import Grid from "./components/usuarios/Grid.js";
import NovaSenha from "./components/usuarios/NovaSenha.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import GlobalStyle from "./styles/global.js";
import logo from "./styles/imagem/Logomarca Cooperval-02.png"
import { FaHome } from "react-icons/fa";
import { ImExit, ImCross } from "react-icons/im";

const Container = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;

  @media (max-width: 1360px) {
    width: 1250px;
  }
`;

const Title = styled.nav`
  height: 6rem;
  background-color: #14141c;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;

  img{
    padding-left: 48rem;
    padding-top: 1%;
  }

  @media (max-width: 1360px) {
    img{
      padding-left: 50rem;
      padding-top: 1%;
    }
  };
    
`;

const H2 = styled.h2`
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 


  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: #FFF;
  padding: 5px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.5);

  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vh; 
  overflow-y: auto; 
`;

const Div2 = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;

const ButtonDashboard = styled.button`
  
  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

const ButtonExit = styled.button`


  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;


function Usuarios() {
  const [users, setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [loginEncoded, setLoginEncoded] = useState();

  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/usuarios/");
      //const res = await axios.get("http://192.168.100.103:8831/usuarios/");
      setUsers(res.data.sort((a, b) => (a.logon > b.logon ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  };



  useEffect(() => {

    getUsers();
  }, [setOnEdit]);

  useEffect(() => {
    // Obter a URL atual
    const currentUrl = window.location.href;

    // Verificar se a URL contém o parâmetro 'login'
    if (currentUrl.includes('?login=')) {
      // Extrair o valor do parâmetro 'login' da URL
      const loginEncoded = currentUrl.split('?login=')[1];
      setLoginEncoded(loginEncoded)
      // Decodificar o valor do parâmetro 'login'
      const logon = atob(decodeURIComponent(loginEncoded));

      //console.log('useEffect', logon);

      // Verificar se logon está presente
      if (!logon) {
        // Redirecionar para a página de login em .php (substitua pela URL correta)
        window.location.href = '/login';
      }
    } else {
      // Se o parâmetro 'login' não estiver presente na URL, redirecionar para a página de login
      window.location.href = '/login';
    }


  }, []);
  


  const [isModalOpenNovaSenha, setIsModalOpenNovaSenha] = useState(false);
  const modalNovaSenha = () => {
    setIsModalOpenNovaSenha(!isModalOpenNovaSenha);
  };

  const handleDashboard = () => {
    const url = `/?login=${loginEncoded}`;
    window.location.href = url;
  };

  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };

  return (
    <>
      <Container>
        <Title>
          <Title2>
            <H2>Cadastro Usuários</H2>
            <img src={logo} style={{ width: '180px' }} alt="Logo" />
          </Title2>
        </Title>

        {isModalOpenNovaSenha && (
          <ModalContainer>
            <ModalContent>
              <ButtonContainer>
                <Button onClick={() => {
                  modalNovaSenha();

                }}>
                  {isModalOpenNovaSenha ? <ImCross color={"red"} /> : ""}
                </Button>
              </ButtonContainer>
              <NovaSenha onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} />
            </ModalContent>
          </ModalContainer>
        )}


        <Form onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} modalNovaSenha={modalNovaSenha} />
        <Grid setOnEdit={setOnEdit} users={users} setUsers={setUsers} />

        <Div2>
          <ButtonDashboard onClick={handleDashboard} title={"Voltar a tela anterior"}>
            <FaHome title={"Voltar a tela anterior"} />
          </ButtonDashboard>
          <ButtonExit onClick={handleExit} title={"Logout, Sair"}>
            <ImExit title={"Logout, Sair"} />
          </ButtonExit>

        </Div2>
      </Container>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Usuarios;
