import React, { useState } from "react";
import styled from "styled-components";
import SelectAvaliacao from "./components/modulos/SelectAvaliacao.js";
import SelectModulo from "./components/modulos/SelectModulo.js";
import SelectPergunta from "./components/modulos/SelectPergunta.js";
import SelectResposta from "./components/modulos/SelectResposta.js";
import Grid from "./components/modulos/Grid";
import GridModulo from "./components/modulos/GridModulo.js";
import GridPergunta from "./components/modulos/GridPergunta.js";
import GridResposta from "./components/modulos/GridResposta.js";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import GlobalStyle from "./styles/global.js";
import logo from "./styles/imagem/Logomarca Cooperval-02.png"
import NovaAvaliacao from "./components/modulos/NovaAvaliacao.js"
import NovoModulo from "./components/modulos/NovoModulo.js"
import NovoPergunta from "./components/modulos/NovoPergunta.js";
import NovoResposta from "./components/modulos/NovoResposta.js"
import { FaHome } from "react-icons/fa";
import { ImExit, ImCross } from "react-icons/im";


const Container = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  margin-top: 1rem;

  @media (max-width: 1360px) {
    width: 1250px;
  }
`;

const Title = styled.nav`
  height: 6rem;
  background-color: #14141c;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;


`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;

  img{
    padding-left: 40rem;
    padding-top: 1%;
  }

  @media (max-width: 1360px) {
    img{
      padding-left: 50rem;
      padding-top: 1%;
    }
  };
    
`;
const Div = styled.div`
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: start; 


  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  background-color: #FFF;
  padding: 5px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.5);

  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  background-color: white;
  max-height: 600px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;


const Div2 = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;



const ButtonDashboard = styled.button`
  
  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;
const ButtonExit = styled.button`


  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

function Modulos() {
  const [users, setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [loginEncoded, setLoginEncoded] = useState();




  useEffect(() => {

    const currentUrl = window.location.href;

    if (currentUrl.includes('?login=')) {

      const loginEncoded = currentUrl.split('?login=')[1];
      setLoginEncoded(loginEncoded)
      const logon = atob(decodeURIComponent(loginEncoded));

      if (!logon) {
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }


  }, []);









  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Inverte o estado do modal
  };


  const [filteredOption, setFilteredOption] = useState("");
  const [isModalOpenModulo, setIsModalOpenModulo] = useState(false);
  const toggleModalModulo = () => {
    setIsModalOpenModulo(!isModalOpenModulo); // Inverte o estado do modal
  };


  const [filteredOptionPergunta, setFilteredOptionPergunta] = useState("");
  const [isModalOpenPergunta, setIsModalOpenPergunta] = useState(false);
  const toggleModalPergunta = () => {
    setIsModalOpenPergunta(!isModalOpenPergunta); // Inverte o estado do modal
  };



  const [filteredOptionResposta, setFilteredOptionResposta] = useState("");
  const [isModalOpenResposta, setIsModalOpenResposta] = useState(false);
  const toggleModalResposta = () => {
    setIsModalOpenResposta(!isModalOpenResposta); // Inverte o estado do modal
  };




  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [editGridPlus, editGrid] = useState(null);
  const toggleModalEdit = () => {
    setIsModalOpenEdit(!isModalOpenEdit); // Inverte o estado do modal
  };

  const [isModalOpenEditModulo, setIsModalOpenEditModulo] = useState(false);
  const [editGridPlusModulo, editGridModulo] = useState(null);
  const toggleModalEditModulo = () => {
    setIsModalOpenEditModulo(!isModalOpenEditModulo); // Inverte o estado do modal
  };

  const [isModalOpenEditPergunta, setIsModalOpenEditPergunta] = useState(false);
  const [editGridPlusPergunta, editGridPergunta] = useState(null);
  const toggleModalEditPergunta = () => {
    setIsModalOpenEditPergunta(!isModalOpenEditPergunta); // Inverte o estado do modal
  };

  const [isModalOpenEditResposta, setIsModalOpenEditResposta] = useState(false);
  const [editGridPlusResposta, editGridResposta] = useState(null);
  const toggleModalEditResposta = () => {
    setIsModalOpenEditResposta(!isModalOpenEditResposta); // Inverte o estado do modal
  };


  const getUsers = async () => {
    try {
      const res = await axios.get("/apinova/criando-avaliacao/");
      //const res = await axios.get("http://192.168.100.103:8831/criando-avaliacao/");
      setUsers(res.data.sort((a, b) => (a.id_avaliacao > b.id_avaliacao ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  };


  useEffect(() => {
    getUsers();

  }, [setUsers]);

  const handleDashboard = () => {
    const url = `/?login=${loginEncoded}`;
    window.location.href = url;
  };

  const handleExit = () => {
    const url = `/`;
    window.location.href = url;
  };


  return (
    <>
      <Container>
        <Title>
          <Title2>

            <h2>Cadastro de Avaliações</h2>
            <img src={logo} style={{ width: '180px' }} alt="Logo" />{/**/}
          </Title2>
        </Title>
        <Div>
          {isModalOpen && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModal();
                    //window.location.reload();
                  }}>
                    {isModalOpen ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <NovaAvaliacao toggleModal={toggleModal} setOnEdit={setOnEdit} getUsers={getUsers} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenModulo && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalModulo();
                    //window.location.reload();
                  }}>
                    {isModalOpenModulo ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <NovoModulo toggleModalModulo={toggleModalModulo} setOnEdit={setOnEdit} getUsers={getUsers} filteredOption={filteredOption} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenPergunta && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalPergunta();
                    //window.location.reload();
                  }}>
                    {isModalOpenPergunta ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <NovoPergunta toggleModalPergunta={toggleModalPergunta} setOnEdit={setOnEdit} getUsers={getUsers} filteredOptionPergunta={filteredOptionPergunta} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenResposta && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalResposta();
                    //window.location.reload();
                  }}>
                    {isModalOpenResposta ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <NovoResposta toggleModalResposta={toggleModalResposta} setOnEdit={setOnEdit} getUsers={getUsers} filteredOptionResposta={filteredOptionResposta} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenEdit && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalEdit();
                    //window.location.reload();
                  }}>
                    {isModalOpenEdit ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <Grid setOnEdit={setOnEdit} users={users} setUsers={setUsers} toggleModalEdit={toggleModalEdit} editGridPlus={editGridPlus} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenEditModulo && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalEditModulo();
                    //window.location.reload();
                  }}>
                    {isModalOpenEditModulo ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <GridModulo setOnEdit={setOnEdit} users={users} setUsers={setUsers} toggleModalEditModulo={toggleModalEditModulo} editGridPlusModulo={editGridPlusModulo} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenEditPergunta && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalEditPergunta();
                    //window.location.reload();
                  }}>
                    {isModalOpenEditPergunta ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <GridPergunta setOnEdit={setOnEdit} users={users} setUsers={setUsers} toggleModalEditPergunta={toggleModalEditPergunta} editGridPlusPergunta={editGridPlusPergunta} />
              </ModalContent>
            </ModalContainer>
          )}

          {isModalOpenEditResposta && (
            <ModalContainer>
              <ModalContent>

                <ButtonContainer>
                  <Button onClick={() => {
                    toggleModalEditResposta();
                    //window.location.reload();
                  }}>
                    {isModalOpenEditResposta ? <ImCross color={"red"} /> : ""}
                  </Button>
                </ButtonContainer>
                <GridResposta setOnEdit={setOnEdit} users={users} setUsers={setUsers} toggleModalEditResposta={toggleModalEditResposta} editGridPlusResposta={editGridPlusResposta} />
              </ModalContent>
            </ModalContainer>
          )}
          <SelectAvaliacao setFilteredOption={setFilteredOption} toggleModal={toggleModal} toggleModalEdit={toggleModalEdit} editGrid={editGrid} />
          <SelectModulo filteredOption={filteredOption} toggleModalModulo={toggleModalModulo} setFilteredOptionPergunta={setFilteredOptionPergunta} toggleModalEditModulo={toggleModalEditModulo} editGridModulo={editGridModulo} />
          <SelectPergunta toggleModalPergunta={toggleModalPergunta} filteredOptionPergunta={filteredOptionPergunta} setFilteredOptionResposta={setFilteredOptionResposta} toggleModalEditPergunta={toggleModalEditPergunta} editGridPergunta={editGridPergunta} />
          <SelectResposta filteredOptionResposta={filteredOptionResposta} toggleModalResposta={toggleModalResposta} toggleModalEditResposta={toggleModalEditResposta} editGridResposta={editGridResposta} />

        </Div>
        <Div2>
          <ButtonDashboard onClick={handleDashboard} title={"Voltar a tela anterior"}>
            <FaHome title={"Voltar a tela anterior"} />
          </ButtonDashboard>
          <ButtonExit onClick={handleExit} title={"Logout, Sair"}>
            <ImExit title={"Logout, Sair"} />
          </ButtonExit>

        </Div2>

      </Container>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Modulos;
