import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import Swal from "sweetalert2";

const Table = styled.table`
  width: 1310px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 0px auto 20px auto;
  word-break: break-all;
  max-height: 2000px;
  overflow-y: scroll;


  @media (max-width: 1024px) {
    width: 965px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 820px) {
    width: 780px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 580px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 420px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px;
    
  }

  
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;

  @media (max-width: 640px) {
    align-items: center; 
    text-align: center;
  }
  @media (max-width: 420px) {
    align-items: center; 
    text-align: center;
  }
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
    
  }
`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: row;
    padding-left: 10px;

    ${(props) => props.onlyWeb && "width: 80%"}

  }
  @media (max-width: 420px) {
    display: flex;
    flex-direction: row;
    padding-left: 10px;

    ${(props) => props.onlyWeb && "width: 80%"}
  }
`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    display: flex;

    padding-left: 10px;
    width: 150px;
    ${(props) => props.onlyWeb && "display: none"}
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
  @media (max-width: 420px) {
    display: flex;

    padding-left: 10px;
    width: 150px;
    ${(props) => props.onlyWeb && "display: none"}
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
`;

export const Td3 = styled.td`
  width: 1310px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span{
    margin: 0px 5px;
  }
`;


const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 640px) {
    width: 520px;
    height: 30px;
  }
  @media (max-width: 420px) {
    width: 220px;
    height: 35px;
  }
`;

const Button = styled.button`
  margin: 10px 10px;
  width: 50px;
  background-color: #e8eaeb;
  cursor: pointer;
  border: none;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

const Div = styled.div`
  background-color: #e8eaeb;
  font-weight: bold;
  height: 25px;
  display:flex;
  align-items: center;

`;

const Grid = ({ users, setUsers, setOnEdit }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);




  const handleEdit = (item) => {
    setOnEdit(item);
  };


  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja deletar esse usuário?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleDelete = async (logon) => {
    const userConfirmed = await showPopUp(); // Aguarde a resolução da Promise

    if (!userConfirmed) {
      return;
    }
    await axios
      .delete("/apinova/usuarios/" + logon)
      //.delete("http://192.168.100.103:8815/usuarios/" + logon)
      .then(({ data }) => {
        const newArray = users.filter((user) => user.logon !== logon);
        setFilteredUsers(newArray);
        setUsers(newArray);
        toast.success("Usuário deletado com sucesso.");
      })
      .catch(({ data }) => toast.error(data));

    setOnEdit(null);
  };



  const handleSearch = async (term) => {


    try {
      const filtered = users.filter((user) => {
        const logon = user.logon ? user.logon.toLowerCase() : ''; // Verifica se user.logon é null ou undefined
        const name = user.name ? user.name.toLowerCase() : ''; // Verifica se user.name é null ou undefined
        const funcao = user.funcao ? user.funcao.toLowerCase() : ''; // Verifica se user.funcao é null ou undefined

        return (
          logon.includes(term.toLowerCase()) ||
          name.includes(term.toLowerCase()) ||
          funcao.includes(term.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

  };
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, users]);





  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  return (

    <>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: login, nome, função."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Div>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <ImArrowLeft size={24} />
          </Button>
          <span>Página {currentPage} de {totalPages}</span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ImArrowRight size={24} />
          </Button>
        </Div>
      </Td3>
      <Table >
        <Thead>

          <Tr>
            <Th onlyWeb>Username:</Th>
            <Th onlyWeb>Nome:</Th>
            <Th onlyWeb>Função:</Th>
            <Th onlyWeb>Admin:</Th>
            <Th onlyWeb>Avaliador:</Th>
            <Th onlyWeb>Auto:</Th>
            <Th onlyWeb>Avaliado:</Th>
            <Th onlyWeb>Edit:</Th>
            <Th onlyWeb>Del:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentItems.map((item, i) => (
            <Tr key={i}>
              <Td width="auto">{item.logon}</Td>
              <Td width="auto">{item.name}</Td>
              <Td2 width="auto" onlyWeb>{item.funcao}</Td2>
              <Td width="auto">{item.admin}</Td>
              <Td width="auto">{item.avaliador}</Td>
              <Td width="auto">{item.autoavaliador}</Td>
              <Td width="auto">{item.avaliado}</Td>
              <Td alignCenter width="3%" onlyWeb>
                <FaEdit onClick={() => handleEdit(item)} color={"gold"} />
              </Td>
              <Td alignCenter width="3%" onlyWeb>
                <FaTrash onClick={() => handleDelete(item.logon)} color={"#BF0426"} />
              </Td>
            </Tr>
          ))}
        </Tbody>


      </Table>

    </>
  );
};


export default Grid;
