import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ImArrowLeft, ImArrowRight, ImSearch } from "react-icons/im";
import { MdRestorePage } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Table = styled.table`
  width: 80%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 0px auto 20px auto;
  word-break: break-all;
  max-height: 2000px;
  overflow-y: scroll;


  @media (max-width: 1024px) {
    width: 965px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 820px) {
    width: 780px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 580px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 420px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px;
    
  }

  
`;

export const Thead = styled.thead``;

export const Tbody = styled.tbody`
  padding-bottom: 25px; 
`;

export const Tr = styled.tr`
  text-align: center;

  @media (max-width: 640px) {
    align-items: center; 
    text-align: center;
  }
  @media (max-width: 420px) {
    align-items: center; 
    text-align: center;
  }
`;

export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;

export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
    
  }
`;

export const Td1 = styled.td`
  padding-top: 15px;
  text-align: center;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: row;
    padding-left: 10px;

    ${(props) => props.onlyWeb && "width: 80%"}

  }
  @media (max-width: 420px) {
    display: flex;
    flex-direction: row;
    padding-left: 10px;

    ${(props) => props.onlyWeb && "width: 80%"}
  }
`;

export const Td2 = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    display: flex;

    padding-left: 10px;
    width: 150px;
    ${(props) => props.onlyWeb && "display: none"}
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
  @media (max-width: 420px) {
    display: flex;

    padding-left: 10px;
    width: 150px;
    ${(props) => props.onlyWeb && "display: none"}
    ${(props) => props.onlyWeb3 && "width: 80%"}
  }
`;

export const Td3 = styled.td`
  width: 1310px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  span{
    margin: 0px 5px;
  }
`;


const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 640px) {
    width: 520px;
    height: 30px;
  }
  @media (max-width: 420px) {
    width: 220px;
    height: 35px;
  }
`;

const Button = styled.button`
  margin: 10px 10px;
  width: 50px;
  background-color: #e8eaeb;
  cursor: pointer;
  border: none;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

const ButtonVisualizar = styled.button`
  margin: 10px 10px;
  width: 20px;
  padding-top: 10px;
  background-color: #fff;
  cursor: pointer;
  border: none;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;
const Div = styled.div`
  background-color: #e8eaeb;
  font-weight: bold;
  height: 25px;
  display:flex;
  align-items: center;

`;




const Grid = ({ users, setUsers, setOnEdit, avaliacao, ModalVisualizarAvaliacao, adminDashboard, logonDashboard, ModalContinuarAvaliacao }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);



  useEffect(() => {
    verificarAdminELogon();
  }, [adminDashboard]);

  //Verifica os valores da prop e se true chama a função desativarBotão
  const verificarAdminELogon = () => {
    if (adminDashboard.adminDashboard === "Nao" || adminDashboard.adminDashboard === "") {
      DesativarBotao();

    }
  };




  function DesativarBotao() {
    const botoes = document.querySelectorAll('.desativaBotao');

    botoes.forEach((botao) => {
      botao.style.display = 'none';
    });
  }




  const handleSearch = async (term) => {
    try {
      const filtered = users.filter((user) => {
        const logon_avaliador = user.logon_avaliador ? user.logon_avaliador.toLowerCase() : '';
        const logon_avaliado = user.logon_avaliado ? user.logon_avaliado.toLowerCase() : '';

        return (
          logon_avaliador.includes(term.toLowerCase()) ||
          logon_avaliado.includes(term.toLowerCase())

        );
      });
      setFilteredUsers(filtered);


    } catch (error) {
      console.error("Erro ao pesquisar:", error);
    }

  };
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, users]);


  function findDescricaoAvaliacao(id_avaliacao) {
    const idAvaliacao = parseInt(id_avaliacao, 10);
    const avalia = avaliacao.find((avalia) => avalia.id_avaliacao === idAvaliacao);

    return avalia ? avalia.descricao : "Descrição não encontrada";
  }




  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Deseja deletar a avaliação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };


  const handleDelete = async (id_aplicacaoavaliacao) => {

    const userConfirmed = await showPopUp(); // Aguarde a resolução da Promise

    if (!userConfirmed) {
      return;
    }

    await axios
    .delete("/apinova/relatorio/" + id_aplicacaoavaliacao)
    //.delete("http://192.168.100.103:8831/relatorio/" + id_aplicacaoavaliacao)
      .then(({ data }) => {
        const newArray = users.filter((user) => user.id_aplicacaoavaliacao !== id_aplicacaoavaliacao);

        setUsers(newArray);
        toast.success(data);
        handleDeleteRespostas(id_aplicacaoavaliacao);
      })
      .catch(({ data }) => toast.error(data));

    setOnEdit(null);
  };
  const handleDeleteRespostas = async (id_aplicacaoavaliacao) => {
    await axios
    .delete("/apinova/relatorio/grid-relatorio/" + id_aplicacaoavaliacao)
     // .delete("http://192.168.100.103:8831/relatorio/grid-relatorio/" + id_aplicacaoavaliacao)
      .then(({ data }) => {
        const newArray = users.filter((user) => user.id_aplicacaoavaliacao !== id_aplicacaoavaliacao);

        setUsers(newArray);
        toast.success(data);
      })
      .catch(({ data }) => toast.error(data));

    setOnEdit(null);
  };




  //Paginação
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Defina a quantidade de itens por página conforme necessário
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  // Função para ir para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  // Função para ir para a página anterior
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  const visualizar = (item) => {
    ModalVisualizarAvaliacao(item);
  };

  const continuarAvaliacao = (item) => {
    if (item.finalizado === 'Sim') {
      toast.error('Essa avaliação está finalizada');
      return;
    } else {
      ModalContinuarAvaliacao(item);
    }
    
    
  };




  return (

    <>
      <Td3 colSpan="7">
        <Input
          type="text"
          placeholder="Pesquisar... Ex: ID, login."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

      </Td3>
      <Table >
        <Thead>

          <Tr>
            <Th >ID:</Th>
            <Th >Data:</Th>
            <Th >Avaliador:</Th>
            <Th >Avaliado:</Th>
            <Th >Avaliação:</Th>
            <Th>Finalizado:</Th>
            <Th></Th>
            <Th></Th>
            <Th className='desativaBotao'></Th>
          </Tr>
        </Thead>
        <Tbody>

          {currentItems

            .map((item, i) => {
              const isCreator = item.logon_avaliador === logonDashboard;
              const isCreator2 = item.logon_avaliado === logonDashboard;
              const isAdmin = adminDashboard === "Sim";

              if (!isCreator && !isAdmin && !isCreator2) {
                return null;
              }
              return (
              <Tr key={i}>
                <Td width="auto">{item.id_aplicacaoavaliacao}</Td>
                <Td width="auto"><div>{new Intl.DateTimeFormat("pt-BR", { month: "2-digit", day: "2-digit" }).format(new Date(item.data || "S/N"))}</div></Td>
                <Td2 width="auto" onlyWeb>{item.logon_avaliador}</Td2>
                <Td width="auto">{item.logon_avaliado}</Td>
                <Td width="auto">{findDescricaoAvaliacao(item.id_avaliacao)}</Td>
                <Td width="auto">{item.finalizado}</Td>
                <ButtonVisualizar type="button" onClick={() => visualizar(item)} title="Visualizar avaliação" >
                  <ImSearch color={"gold"} size={22} />
                </ButtonVisualizar>

                <ButtonVisualizar type="button" onClick={() => continuarAvaliacao(item)} title="Continuar avaliação" >
                  <MdRestorePage color={"blue"} size={22} />
                </ButtonVisualizar>

                <ButtonVisualizar
                  onClick={() => handleDelete(item.id_aplicacaoavaliacao)} className='desativaBotao'>
                  <FaTrash color={"red"} size={22} />
                </ButtonVisualizar>

              </Tr>
            )})}
        </Tbody>
      </Table>
      <Td3 colSpan="7">
        <Div>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <ImArrowLeft size={24} />
          </Button>
          <span>Página {currentPage} de {totalPages}</span>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <ImArrowRight size={24} />
          </Button>
        </Div>
      </Td3>
    </>
  );
};


export default Grid;
