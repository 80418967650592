import axios from "axios";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImFloppyDisk, ImLock, ImRedo } from "react-icons/im";

const FormContainer = styled.form`
  width: auto;
  background-color: #fff;
  justify-content: space-between; 
  display: flex; 
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  align-items: flex-end;
  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;

  @media (max-width: 1440px) {
    width: 1200px;
    padding: 10px;
    display: flex;
    justify-content: space-between; 
  }

  @media (max-width: 1360px) {
    width: 1200px;
    padding: 10px;
    display: flex;
    justify-content: space-between; 
  }

  @media (max-width: 1024px) {
    width: 924px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 820px) {
    width: 730px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 540px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  select {
    width: 248px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 37px;
  }

`;

const Input = styled.input`
  width: 245px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1440px) {
    width: 280px;
    height: 37px;
  }

  @media (max-width: 1360px) {
    width: 280px;
    height: 37px;
  }

  @media (max-width: 1024px) {
    width: 280px;
    height: 37px;
  }
  @media (max-width: 820px) {
    width: 190px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 270px;
    height: 37px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
`;

const Input2 = styled.input`
  width: 350px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;


  
  @media (max-width: 1024px) {
    width: 500px;
    height: 37px;
  }
  @media (max-width: 820px) {
    width: 190px;
    height: 37px;
  }
  @media (max-width: 640px) {
    width: 350px;
    height: 37px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
  
`;



const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 19px 1px 1px 1px;
  @media (max-width: 640px) {
    flex-direction: row;
  }
  @media (max-width: 420px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  font-size: 17px;
  font-weight: bold;
  height: 30px;
  width: 170px;
  margin: 10px 10px;

  cursor: pointer;
  border: none;
  background-color: #FFF;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`;


const Div = styled.td`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    justify-content: flex-end;
    flex-direction: row;
  }
  @media (max-width: 820px) {
    justify-content: flex-end;
    flex-direction: row;
  }
  @media (max-width: 640px) {
    flex-direction: column;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Div2 = styled.td`
  display: flex;
  flex-direction: column;
`;

const Div3 = styled.td`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 640px) {
    flex-direction: column;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
  
`;


const Form = ({ getUsers, onEdit, setOnEdit, modalNovaSenha, limpaForm2 }) => {

  const ref = useRef();


  useEffect(() => {
    if (onEdit) {
      //console.log("Valores em onEdit:", onEdit);
      const user = ref.current;

      user.logon.value = onEdit.logon;
      user.name.value = onEdit.name;
      user.funcao.value = onEdit.funcao;
      user.admin.value = onEdit.admin;
      user.avaliador.value = onEdit.avaliador;
      user.autoavaliador.value = onEdit.autoavaliador;
      user.avaliado.value = onEdit.avaliado;
    };
  }, [onEdit]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;
    const logonValue = user.logon.value;
    const nameValue = user.name.value;
    const funcaoValue = user.funcao.value;
    const adminValue = user.admin.value;
    const avaliadorValue = user.avaliador.value;
    const autoavaliadorValue = user.autoavaliador.value;
    const avaliadoValue = user.avaliado.value;

    //console.log("Opção escolhida pelo usuário:", adminValue);

    if (!logonValue || !nameValue || !funcaoValue || !adminValue || !avaliadorValue || !autoavaliadorValue || !avaliadoValue) {
      return toast.warn("Preencha todos os campos!");
    }


    if (onEdit) {
      // Adicione um console.log aqui para verificar o valor de admin antes de enviar a atualização
      //console.log("Valor escolhido em admin (Editar):", user.admin.value);

      await axios
        //.put("http://192.168.100.103:8815/usuarios/" + onEdit.logon, {
        .put("/apinova/usuarios/" + onEdit.logon, {
          logon: user.logon.value,
          name: user.name.value,
          funcao: user.funcao.value,
          admin: user.admin.value,
          avaliador: user.avaliador.value,
          autoavaliador: user.autoavaliador.value,
          avaliado: user.avaliado.value,
        })
        .then(({ data }) => toast.success(data))
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            const details = error.response.data.details;
            const dbError = error.response.data.dbError;
            console.log("Error:", errorMessage);
            console.log("Details:", details);
            console.log("Database Error:", dbError);
            toast.error("Erro ao editar: " + errorMessage);
          }
        });
    } else {
      // Adicione um console.log aqui para verificar o valor de admin antes de enviar a criação
      //console.log("Valor escolhido em admin (Adicionar):", user.admin.value);

      await axios
        //.post("http://192.168.100.103:8815/usuarios/", {
        .post("/apinova/usuarios/", {
          logon: user.logon.value,
          name: user.name.value,
          funcao: user.funcao.value,
          admin: user.admin.value,
          avaliador: user.avaliador.value,
          autoavaliador: user.autoavaliador.value,
          avaliado: user.avaliado.value,
        })
        .then(({ data }) => toast.success(data))
        .catch((error) => {
          if (error.response) {
            const errorMessage = error.response.data.error;
            const details = error.response.data.details;
            const dbError = error.response.data.dbError;
            console.log("Error:", errorMessage);
            console.log("Details:", details);
            console.log("Database Error:", dbError);
            toast.error("Erro ao adicionar: " + errorMessage);
          }
        });
    }

    user.logon.value = "";
    user.name.value = "";
    user.funcao.value = "";
    user.admin.value = "";
    user.avaliador.value = "";
    user.autoavaliador.value = "";
    user.avaliado.value = "";

    setOnEdit(null);
    getUsers();
  };


  



  const novaSenha = () => {
    if (!onEdit) {
      toast.error("Escolha um usuário antes de criar uma senha");
    } else {
      modalNovaSenha();
    }
    
  };



  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <Div3>
          <Div2>
            <Div>

              <InputArea>
                <Label>Username:</Label>
                <Input name="logon" type="text" />
              </InputArea>

              <InputArea>
                <Label>Nome:</Label>
                <Input2 name="name" type="text" />
              </InputArea>

              <InputArea>
                <Label>Função:</Label>
                <Input2 name="funcao" type="text" />
              </InputArea>

            </Div>
            <Div>

              <InputArea>
                <Label>Administrador:</Label>
                <select name="admin">
                  <option value="" disabled selected>Selecione uma opção</option>
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                </select>
              </InputArea>

              <InputArea>
                <Label>Avaliador:</Label>
                <select name="avaliador">
                  <option value="" disabled selected>Selecione uma opção</option>
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                </select>
              </InputArea>

              <InputArea>
                <Label>Autoavaliador:</Label>
                <select name="autoavaliador">
                  <option value="" disabled selected>Selecione uma opção</option>
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                </select>
              </InputArea>

              <InputArea>
                <Label>Avaliado:</Label>
                <select name="avaliado">
                  <option value="" disabled selected>Selecione uma opção</option>
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                </select>
              </InputArea>

            </Div>


          </Div2>

          <ButtonContainer>
            <Button title="Crie uma senha para o usuário" type="button" onClick={novaSenha}>
              <ImLock color={"blue"}/> Crie uma senha
            </Button>
            
            <Button title="Limpa o fomulário" type="button" onClick={() => window.location.reload()}>
              <ImRedo  color={"gold"}/> Limpar os campos
            </Button>

            <Button title="Salva as informações preenchidas" type="submit">
              <ImFloppyDisk color={"#03A64A"}/> Salvar
            </Button>
          </ButtonContainer>
        </Div3>

      </FormContainer>
    </div>

  );
};

export default Form;


