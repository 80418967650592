import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FaHome } from "react-icons/fa";
import { ImExit } from "react-icons/im";
import Swal from "sweetalert2";


const FormContainer = styled.form`
  width: 1160px;
  background-color: #fff;
  display: flex; 
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  align-items: flex-end;
  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 884px;
    padding: 10px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 860px) {
    width: 700px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 470px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 440px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 480px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
  @media (max-width: 375px) {
    width: 235px;
    padding: 2px;
    margin: 2px 0px 0px 0px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: row;
  Label {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const FlexRow2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  Label{
    font-size: 18px;
  }
  
`;
const FlexRow3 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  Label{
    font-size: 18px;
  }
  
`;
const InputArea = styled.div`
  .largura-custom {
    width: 200px; 
  }
  height: 70px;
  select {
    width: 1160px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
  }
  
  @media (max-width: 1024px) {
    select {
      width: 875px;
    } 
  }
  @media (max-width: 860px) {
    select {
      width: 700px;
    } 
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    padding-left: 15px;
    select {
      width: 440px;
    }
  }
  @media (max-width: 480px) {
    margin: 15px 0px;
    padding-left: 15px;
    select {
      width: 310px;
    }
  }
  @media (max-width: 375px) {
    margin: 15px 0px;
    padding-left: 12px;
    select {
      width: 210px;
    }
  }
`;
const InputArea2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 10px 0px;

  select {
    width: 220px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
  }

  @media (max-width: 1024px) {
    width: 850px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 860px) {
    padding: 15px 0px;
    width: 520px;
  }
  @media (max-width: 640px) {
    padding: 15px 0px;
    width: 540px;
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
  }
  @media (max-width: 480px) {
    padding: 15px 0px;
    width: 340px;
    margin: 2px 0px 0px 0px;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    padding: 15px 0px;
    width: 210px;
    margin: 2px 0px 0px 0px;
    flex-direction: column;
  }
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-left:15px;
  input{
    width: 900px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
    height: 40px;
  }

  @media (max-width: 1024px) {
    input{
      width: 620px;
    }
  }
  @media (max-width: 860px) {
    input{
      width: 440px; 
    }
  }
  @media (max-width: 640px) {
    width: 540px;
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
    margin-left:15px;
    input{
      width: 420px; 
    }
  }
  @media (max-width: 480px) {
    width: 340px;
    margin: 2px 0px 0px 0px;
    flex-direction: column;
    margin-left:15px;
    input{
      width: 290px; 
    }
  }
  @media (max-width: 375px) {
    width: 240px;
    margin: 2px 0px 0px 0px;
    flex-direction: column;
    margin-left:15px;
    input{
      width: 185px; 
    }
  }
`;
const Div2 = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    display: flex;
    select {
      width: 700px; 
    }

  }
  @media (max-width: 640px) {
    width: 540px;
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
    margin-left:15px;
    select {
      width: 310px; 
    }
  }
  @media (max-width: 480px) {
    width: 340px;
    margin: 2px 0px 0px 0px;
    flex-direction: column;
    margin-left:15px;
    select {
      width: 270px; 
    }
  }
`;
const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  background-color:   #7A7A7A;
  color: white;
  height: 42px;
  font-weight: bold;
  margin-left:5px;
  
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
    background-color: #000000;
  }
`;
const Input = styled.input`
  width: 200px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 40px;

  @media (max-width: 1024px){
    width: 200px;
  }
  @media (max-width: 820px){
    width: 200px;
  }
  @media (max-width: 640px) {
    width: 420px;
  }
  @media (max-width: 480px) {
    width: 290px;
  }
  @media (max-width: 375px) {
    width: 185px;
  }
`;
const Div3 = styled.div`
  position: fixed;
  top:0;
  left:0;
  display: flex;
  flex-direction: column;
`;
const ButtonDashboard = styled.button`
  
  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;
const ButtonExit = styled.button`


  padding: 12px;
  cursor: pointer;
  border-radius: 49%;
  border: none;
  background-color: #e8eaeb;
  color: #14141c;
  font-size: 20px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;


const Form = ({ users, getUsers, onEdit, setOnEdit, setFilteredOption, setHandleSaveAvaliacao, setInserted }) => {
  const ref = useRef();
  const [avaliacoesOptions, setAvaliacoesOptions] = useState([]);
  const [selectedAvaliadorOption, setSelectedAvaliadorOption] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [aplicacoesOptions, setAplicacoesOptions] = useState([]);
  const [logon, setLogon] = useState(""); //Logon do usuário
  const [nomeUsuario, setNomeUsuario] = useState(""); // Atualiza o nome do Usuário
  const [isIniciarAvaliacaoEnabled, setIsIniciarAvaliacaoEnabled] = useState(false);
  const [loginEncoded, setLogonEncoded] = useState([]); //Ler o usuario na URL
  const [isSelectDisabled, setIsSelectDisabled] = useState(false); //Desabilita o select após iniciar avaliação
  const [isAutoavaliacaoIniciada, setIsAutoavaliacaoIniciada] = useState(false);  // Cancelar avaliação ao clicar no FaHome
  const [insertedId, setInsertedForm] = useState(""); // passando o insertedId para o FaHome/handleDashboard
  const [usersAvaliados, setUsersAvaliados] = useState([]);

  //Ler a url
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('?login=')) {
      const loginEncoded = currentUrl.split('?login=')[1];
      const logon = atob(decodeURIComponent(loginEncoded));
      //console.log('useEffect', logon);
      setLogonEncoded(loginEncoded);
      if (!logon) {
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }
  }, []);


  //Atualiza o nome do usuario avaliador
  const dadosUsuarios = async (logon) => {
    try {
      //const response = await axios.get("http://192.168.100.103:8831/usuarios/");
      const response = await axios.get("/apinova/usuarios/");

      const usuarioEncontrado = response.data.find((user) => user.logon === logon);
      if (usuarioEncontrado) {
        setNomeUsuario(usuarioEncontrado.name);
      } else {
        setNomeUsuario("");
      }
    } catch (error) {
      setNomeUsuario(""); // Limpa o nome em caso de erro
    }
  };


  //Le a URL e verifica se o logon é Avaliador
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const loginEncoded = urlParams.get('login');
    const logon = atob(decodeURIComponent(loginEncoded));
    setLogon(logon);


    const fetchAplicacoes = async () => {
      try {
        //const response = await axios.get("http://192.168.100.103:8831/avaliacao/tab_pessoas/");
        const response = await axios.get("/apinova/avaliacao/tab_pessoas/");
        setAplicacoesOptions(response.data);

        const usersAvaliados = response.data.filter(item => item.avaliado === "Sim");
        //console.log("Avaliados", usersAvaliados);
        setUsersAvaliados(usersAvaliados);



        const userAvaliador = response.data.find(item => item.logon === logon && item.avaliador === "Sim");
        if (userAvaliador) {
        } else {
          window.alert("Você não possui atribuição de Avaliador, por favor entre em contato com o RH.");
          window.location.href = '/';
        }
      } catch (error) {
        console.error("Erro ao obter avaliações:", error);
      }
    };

    dadosUsuarios(logon);
    fetchAplicacoes();
  }, []);


  //fetch que retorna as avaliações cadastradas
  useEffect(() => {
    const fetchAvaliacoes = async () => {
      try {
        //const response = await axios.get("http://192.168.100.103:8831/avaliacao/tab_sistema_avaliacao");
        const response = await axios.get("/apinova/avaliacao/tab_sistema_avaliacao");
        setAvaliacoesOptions(response.data);
      } catch (error) {
        console.error("Erro ao obter avaliações:", error);
      }
    };

    fetchAvaliacoes();
  }, []);

 

  //Salva a avaliação 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAvaliadorOption || !selectedValue) {
      return toast.warn("Preencha todos os campos!");
    }
    try {
      const requestData = {
        logon_avaliador: logon,
        logon_avaliado: selectedAvaliadorOption,
        id_avaliacao: selectedValue,
        finalizado: "Não",
      };
      //console.log("Teste avaliação", requestData);
      let response;
      let insertedId = null;

      if (onEdit) {
        response = await axios.put(
          //"http://192.168.100.103:8831/autoavaliacao" + onEdit.id_aplicacaoavaliacao,
          "/apinova/autoavaliacao" + onEdit.id_aplicacaoavaliacao,
          requestData
        );
      } else {
        //response = await axios.post("http://192.168.100.103:8831/autoavaliacao", requestData);
        response = await axios.post("/apinova/autoavaliacao", requestData);
      }



      if (response.data && response.data.insertedId) {
        insertedId = response.data.insertedId;
        setInsertedForm(insertedId)
        setInserted(insertedId);
      }



      handleFilterClick()
      setOnEdit(null);
      getUsers();


      return true;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.error === 'Essa avaliação já foi realizada!.') {
          toast.warn("Essa avaliação já foi realizada!");
        } else {
          toast.error("Essa avaliação já foi realizada!");
        }
      } else {
        toast.error("Essa avaliação já foi realizada!");
      }
      return false;
    }
  };

  const showPopUp = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Você iniciou uma avaliação. Deseja sair e descartar a avaliação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          container: 'custom-swal-container',
        },
      }).then((result) => {
        resolve(result.value); // Resolve a Promise com o valor result.value
      });
    });
  };

  // Função que direciona para o Dashboard
  const handleDashboard = async () => {

    if (isAutoavaliacaoIniciada) {

      const shouldExit = await showPopUp();

      if (!shouldExit) {
        return;

      } else if (insertedId) {


        axios
          //.delete(`http://192.168.100.103:8831/avaliacao/${insertedId}`)
          .delete(`/apinova/avaliacao/${insertedId}`)

          .then(() => {

            handleDeleteRespostas(insertedId);

            const url = `/?login=${loginEncoded}`;

            window.location.href = url;
          })
          .catch((error) => {

            const url = `/?login=${loginEncoded}`;
            window.location.href = url;
          });
      } else {

        const url = `/?login=${loginEncoded}`;
        window.location.href = url;
      }
    } else {

      const url = `/?login=${loginEncoded}`;
      window.location.href = url;
    }
  };

///////////////////////////
  // Função que direciona para o Sair
  const handleExit = async () => {

    if (isAutoavaliacaoIniciada) {


      const shouldExit = await showPopUp();


      if (!shouldExit) {

        return;
      } else if (insertedId) {

        console.log(insertedId);
        axios
          //.delete(`http://192.168.100.103:8831/autoavaliacao/${insertedId}`)
          .delete(`/apinova/autoavaliacao/${insertedId}`)

          .then(() => {
            handleDeleteRespostas(insertedId);
            const url = `/`;
            window.location.href = url;
          })

          .catch((error) => {
            const url = `/`;
            window.location.href = url;
          });

      } else {
        //const url = `http://192.168.100.103:3015/?login=${loginEncoded}`;
        const url = `/`;
        window.location.href = url;
      }
    } else {
      //const url = `http://192.168.100.103:3015/?login=${loginEncoded}`;
      const url = `/`;
      window.location.href = url;
    }
  };

  //////////////////////////////
  const handleDeleteRespostas = async (insertedId) => {
    await axios
      //.delete("http://192.168.100.103:8831/relatorio/grid-relatorio/" + insertedId)
      .delete("/apinova/relatorio/grid-relatorio/" + insertedId)
      .then(({ data }) => {
        const newArray = users.filter((user) => user.id_aplicacaoavaliacao !== insertedId);


        toast.success(data);
      })
      .catch(({ data }) => toast.error(data));

    setOnEdit(null);
  };

  useEffect(() => {
    if (logon && selectedAvaliadorOption && selectedValue) {
      setIsIniciarAvaliacaoEnabled(true);
    } else {
      setIsIniciarAvaliacaoEnabled(false);
    }
  }, [logon, selectedAvaliadorOption, selectedValue]);



  const handleFilterClick = () => {
    if (selectedValue) {
      setFilteredOption(selectedValue);
      setHandleSaveAvaliacao(true);
      setIsIniciarAvaliacaoEnabled(false);
    } else {
      toast.warn("Selecione uma opção antes de pesquisar.");
    }
  };


  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <FlexRow2>
          <InputArea2>
            <Div2>
              <Label>Login Avaliador:</Label>
              <Input name="logon_avaliador" value={logon} readOnly disabled />
            </Div2>
            <Div>
              <Label>Nome:</Label>
              <input name="name" value={nomeUsuario} readOnly disabled />
            </Div>
          </InputArea2>


          <InputArea >
            <Label>Avaliado:</Label>
            <select name="logon_avaliado"
              isSearchable={true}
              value={selectedAvaliadorOption}
              disabled={isSelectDisabled}
              onChange={(e) => {
                const selectedLogon = e.target.value;
                //console.log("Selected logon:", selectedLogon);
                setSelectedAvaliadorOption(selectedLogon);
              }}>
              <option value="">Selecione um usuário</option>
              {usersAvaliados.map((user, index) => (
                <option key={index} value={user.logon}>
                  {user.logon} - {user.name}
                </option>
              ))}
            </select>
          </InputArea>
        </FlexRow2>


        <FlexRow>
          <InputArea>
            <Label>Escolha uma avaliação:</Label>
            {avaliacoesOptions.length > 0 ? (
              <select
                name="id_avaliacao"
                onChange={(e) => setSelectedValue(e.target.value)}
                value={selectedValue}
                disabled={isSelectDisabled}
              >
                <option value="">Escolha uma opção</option>
                {avaliacoesOptions.map((avaliacao) => (
                  <option key={avaliacao.id_avaliacao} value={avaliacao.id_avaliacao}>
                    {avaliacao.descricao}
                  </option>
                ))}
              </select>
            ) : (
              <p>Carregando...</p>
            )}
          </InputArea>
        </FlexRow>


        <FlexRow3>
          <Button
            type="submit"
            onClick={async (e) => {
              e.preventDefault();
              const result = await handleSubmit(e);
              setIsAutoavaliacaoIniciada(true);
              setIsSelectDisabled(true);
              // Apenas chamar handleFilterClick quando a inserção for bem-sucedida
              //handleFilterClick();
            }}
            disabled={!isIniciarAvaliacaoEnabled}
            style={{ display: isIniciarAvaliacaoEnabled ? 'block' : 'none' }}
          >
            Iniciar Avaliação
          </Button>
        </FlexRow3>



      </FormContainer>
      <Div3>
        <ButtonDashboard onClick={handleDashboard} title={"Voltar"}>
          <FaHome title={"Voltar"} />
        </ButtonDashboard>
        <ButtonExit onClick={handleExit} title={"Logout"}>
          <ImExit title={"Sair"} />
        </ButtonExit>
      </Div3>

    </div>

  );

};



export default Form;
