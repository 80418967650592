import React, { useEffect } from 'react';
import styled from "styled-components";







const Table = styled.table`
  display: flex;
  justify-content: space-around; 
  width: 1160px;

  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  align-items: flex-end;

  @media (max-width: 1024px) {
    width: 924px;
    align-items: center; 
  }

  @media (max-width: 860px) {
    width: 760px;
    align-items: center; 
  }

  @media (max-width: 640px) {
    flex-direction: column;
    width: auto;
    align-items: center; 

    .desativaBotao {
      display: none;
    }
  }

`;


const CreateButton = styled.a`
  display: flex; /* Torna o elemento flexível */
  align-items: center; /* Centraliza verticalmente o conteúdo */
  padding: 7px 15px; 
  background-color: #03A64A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px; 
  text-align: center;
  text-decoration: none;
  margin: 5px;
  transition: transform 0.3s;
  gap: 5px 5px 5px 10px;
  text-align: center;
  border: 1px solid #97A6A0;
  &:hover {
    transform: scale(1.1);
    background-color: #03A64A;
  }

  @media (max-width: 1024px) {
    height: 50px;
  }

  @media (max-width: 640px) {
    width: 250px;
  }
`;


const Grid = (adminDashboard, logonDashboard) => {

  const urlParams = new URLSearchParams(window.location.search);
  const loginEncoded = urlParams.get('login');
  const logon = atob(decodeURIComponent(loginEncoded));
  const logonEncoded = encodeURIComponent(btoa(logon));

  useEffect(() => {
    verificarAdminELogon();
  }, [adminDashboard]);

  //Verifica os valores da prop e se true chama a função desativarBotão
  const verificarAdminELogon = () => {
    if (adminDashboard.adminDashboard === "Nao" || adminDashboard.adminDashboard === "") {
      DesativarBotao();
      
    }
  };
  




  function DesativarBotao() {
    // Seleciona todos os elementos com a classe 'desativaBotao'
    const botoes = document.querySelectorAll('.desativaBotao');

    
    botoes.forEach((botao) => {
      botao.style.display = 'none';
    });
  }


  //Funções para direcionar adicionando o logon no URL
  const handleClick = () => {
    const url = `/avaliacao/?login=${logonEncoded}`;
    window.location.href = url;
  };
  const handleClick2 = () => {
    const url = `/usuarios/?login=${logonEncoded}`;
    window.location.href = url;
  };

  const handleClick4 = () => {
    const url = `/criando-avaliacao/?login=${logonEncoded}`;
    window.location.href = url;
  };
  const handleClick5 = () => {
    const url = `/relatorio/?login=${logonEncoded}`;
    window.location.href = url;
  };
  const handleClick6 = () => {
    const url = `/autoavaliacao/?login=${logonEncoded}`;
    window.location.href = url;
  };

















  return (
    <Table>
      <CreateButton onClick={handleClick4} className='desativaBotao'>
        Cadastro de avaliações
      </CreateButton>
      <CreateButton onClick={handleClick2} className='desativaBotao'>
        Cadastro de usuários
      </CreateButton>
      <CreateButton onClick={handleClick5} className='desativaBotao'>
        Relatórios
      </CreateButton>
      <CreateButton onClick={handleClick6}>
        Autoavaliação
      </CreateButton>
      <CreateButton onClick={handleClick}>
        Avaliação
      </CreateButton>



    </Table>
  );
};


export default Grid;
