import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FaPlus, FaEdit } from "react-icons/fa";




const Div = styled.div`
  background-color: #fff;
  width: 1140px;
  display: flex;
  flex-direction: row;
  align-items: center;

`;

const FormContainer = styled.form`
  display: flex; 
  padding: 20px 0px 00px 20px;
  align-items: flex-end;
  margin: 10px 0px 20px 10px; 
`;
const FlexRow = styled.div`
  display: flex;
  justify-content: center; /* Centraliza horizontalmente os elementos */
  align-items: center; /* Centraliza verticalmente os elementos */
  flex-direction: row;
  Label {
    font-size: 18px;
  }
`;
const InputArea = styled.div`
  display: flex;
  flex-direction:  column;  
  height: 70px;
  select {
    width: 1000px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #bbb;
    border-radius: 1px;
  }
  
  @media (max-width: 1024px) {
    select {
      width: 755px;
    } 
  }
  @media (max-width: 820px) {
    select {
      width: 650px;
    } 
  }
  @media (max-width: 640px) {
    select {
      width: 470px;
    } 
  }
  @media (max-width: 500px) {
    select {
      width: 330px;
    }
  }
  @media (max-width: 420px) {
    select {
      width: 250px;

    }
  }
`;
const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
export const TdButton = styled.td`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;

  margin: 25px 0px 0px 5px;
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;



const Form = ({ toggleModalResposta, filteredOptionResposta, editGridResposta, toggleModalEditResposta }) => {
  const ref = useRef();
  const [avaliacoesOptions, setAvaliacoesOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");



  useEffect(() => {
    const fetchAvaliacoes = async () => {
      try {
        //const response = await axios.get("http://192.168.100.103:8815/criando-avaliacao/criando-resposta");
        const response = await axios.get("/apinova/criando-avaliacao/criando-resposta");
        setAvaliacoesOptions(response.data);
      } catch (error) {
        console.error("Erro ao obter avaliações:", error);
      }
    };

    fetchAvaliacoes();
  });

  

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  


  const novaAvaliacao = () => {
    toggleModalResposta();
  };

  const novaEdicao = () => {
    toggleModalEditResposta();
    editGridResposta(avaliacoesOptions);
  };


  const verificarEExibirToasty = () => {
    if (!filteredOptionResposta) {
      // Exibe um toasty indicando que uma avaliação deve ser escolhida
      toast.error("Escolha uma pergunta antes de criar um nova resposta");
      // Outras opções disponíveis dependendo da biblioteca de toasty que você está usando
    } else {
      // Executa a função novaAvaliacao se filteredOption não for null ou vazio
      novaAvaliacao();
    }

  };
  //console.log(filteredOptionResposta)
  return (

    <>
      <Div>
        <FormContainer ref={ref} >
          <FlexRow>
            <InputArea>
              <Label>Escolha uma resposta:</Label>
              {avaliacoesOptions.length > 0 ? (
                <select
                  name="id_faixa"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    handleSelectChange(e);
                    if (selectedValue !== "") {
                     
                    }
                  }}
                  value={selectedOption}
                >
                  <option value="">Escolha uma opção</option>
                  {avaliacoesOptions
                    .filter((avaliacao) => avaliacao.id_item_pergunta === filteredOptionResposta.selectedValueRespostaResposta)
                    .map((avaliacao) => {
                      //console.log(`Valor do id_avaliacao: ${avaliacao.id_avaliacao},  Módulo: ${avaliacao.id_item_avaliacao} Descrição: ${avaliacao.descricao}`);
                      return (
                        <option key={avaliacao.id_faixa} value={avaliacao.id_faixa}>
                          {avaliacao.descricao}
                        </option>
                      );
                    })}
                </select>
              ) : (
                <p>Carregando...</p>
              )}
            </InputArea>

          </FlexRow>
        </FormContainer>
        <TdButton onClick={verificarEExibirToasty} title={"Crie um nova resposta"} width="4%" style={{ textAlign: 'center' }}>
          <FaPlus title={"Crie um novo módulo"} size={20} color={"#03A64A"}/>Nova
        </TdButton>
        <TdButton onClick={novaEdicao} title={"Edite as resposta"} width="3%" style={{ textAlign: 'center' }}>
          <FaEdit title={"Edite as resposta"} size={20} color={"gold"}/>Edite
        </TdButton>
      </Div>

    </>

  );

};



export default Form;
