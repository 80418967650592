import axios from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";


const FormContainer = styled.form`
  min-width: 1000px;
  display: flex; 
  border-radius: 1px;
  align-items: flex-end;  

  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 924px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 820px) {
    width: 720px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 540px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 440px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;

const InputArea = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 905px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 800px;
    height: 35px;
  }
  @media (max-width:820px) {
    width: 690px;
    height: 35px;
  }
  @media (max-width: 640px) {
    width: 520px;
    height: 35px;
  }
  @media (max-width: 500px) {
    width: 420px;
    height: 35px;
  }
  @media (max-width: 420px) {
    width: 320px;
    height: 35px;
  }
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 10px;

  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
  border: none;
  background-color: #FFF;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);

  }
`;


const Form = ({ getUsers, setOnEdit }) => {
  const ref = useRef();


  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;
    const descricaoValue = user.descricao.value;


    if (!descricaoValue) {
      return toast.warn("Preencha todos os campos!");
    }

    await axios
    //.post("http://192.168.100.103:8815/criando-avaliacao/", {
    .post("/apinova/criando-avaliacao/", {
      descricao: user.descricao.value,

    })

    .then(({ data }) => toast.success(data))
    .catch((error) => {
      if (error.response) {
        const errorMessage = error.response.data.error;
        const details = error.response.data.details;
        const dbError = error.response.data.dbError;
        console.log("Error:", errorMessage);
        console.log("Details:", details);
        console.log("Database Error:", dbError);
        toast.error("Erro ao adicionar: " + errorMessage);
      }
    });

    user.descricao.value = "";
    setOnEdit(null);
    getUsers();
  };



  return (
    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <InputArea>
          <Label>Crie uma nova avaliação:</Label>
          <Input name="descricao" type="text" />
        </InputArea>


        <ButtonContainer>
          <Button title="Salvar" type="submit">
            <ImCheckmark size={18} color={'#03A64A'}/> Salvar
          </Button>
        </ButtonContainer>
      </FormContainer>

    </div>

  );
};

export default Form;


