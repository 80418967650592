import axios from "axios";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ImCheckmark } from "react-icons/im";


const FormContainer = styled.form`
  min-width: 1000px;
  display: flex; 
  border-radius: 1px;
  align-items: flex-end;
  gap: 5px;
  margin: 10px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    width: 924px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 820px) {
    width: 720px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 640px) {
    width: 540px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 500px) {
    width: 440px;
    padding: 20px;
    display: flex;
    justify-content: space-between; 
  }
  @media (max-width: 420px) {
    width: 340px;
    padding: 15px;
    margin: 2px 0px 0px 0px;
  }
`;

const FlexRow2 = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  Label{
    font-size: 18px;
  }
  
`;


const InputArea3 = styled.div`
  display: flex;

  flex-direction: column;
`;

const Input = styled.input`
  width: 990px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 40px;

  @media (max-width: 1024px){
    width: 750px;
  }
  @media (max-width: 820px) {
    width: 560px;
  }
  @media (max-width: 640px) {
    width: 365px;
  }
  @media (max-width: 500px) {
    width: 225px;
  }
  @media (max-width: 420px) {
    width: 145px;
  }
`;

const Label = styled.label`
  color: #4f4f4f;
  font-weight: bold;
  font-size: 17px;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; 
  padding-left: 10px;

  @media (max-width: 500px) {
    justify-content: center; 
  }
`;

const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
  border: none;
  background-color: #FFF;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);

  }
`;



const Form = ({ getUsers, setOnEdit, filteredOptionResposta }) => {
  const ref = useRef();

  const [descricaoValue, setDescricaoValue] = useState("");




  const handleDescricaoChange = (e) => {
    setDescricaoValue(e.target.value);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = ref.current;

    if (!descricaoValue) {
      return toast.warn("Preencha todos os campos!");
    }

    try {
      const requestData = {
        descricao: descricaoValue,
        id_avaliacao: filteredOptionResposta.filteredOptionResposta,
        id_item_avaliacao: filteredOptionResposta.selectedValueResposta,
        id_item_pergunta: filteredOptionResposta.selectedValueRespostaResposta,
      };
      await axios.post("/apinova/criando-avaliacao/criando-resposta/", requestData);
      //await axios.post("http://192.168.100.103:8815/criando-avaliacao/criando-resposta/", requestData);

      user.descricao.value = "";



      setDescricaoValue("");
      setOnEdit(null);
      getUsers();
      toast.success("Dados salvos com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
      toast.error("Erro ao salvar os dados.");
    }
  };





  return (

    <div>
      <FormContainer ref={ref} onSubmit={handleSubmit}>
        <FlexRow2>
          <InputArea3>
            <Label>Crie uma nova resposta para a pergunta:</Label>
            <Input
              name="descricao"
              type="text"
              onChange={handleDescricaoChange}
              value={descricaoValue}
            />
          </InputArea3>
          
        </FlexRow2>
        <ButtonContainer>
          <Button title="Salvar" type="submit">
            <ImCheckmark size={18} color={'#03A64A'} /> Salvar
          </Button>
        </ButtonContainer>
      </FormContainer>
    </div>

  );

};



export default Form;
