import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Form from "./components/login/Form.js";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import GlobalStyle from "./styles/global";
import logo from "./components/login/Logomarca Cooperval-02.png";
import "./components/login/style.css";



const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 460px;
  margin-top: 25%;
  height: auto;
  background-color: #f5f5f5;



  @media (max-width: 1360px) {
    width: 460px;
  }
  @media (max-width: 1024px) {
    width: 460px;
  }
  @media (max-width: 860px) {
    width: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 640px) {
    width: 400px;
  }
  @media (max-width: 480px) {
    width: 360px;
    margin: 5% auto auto auto;
  }
  @media (max-width: 376px) {
    width: 340px;
    margin: 20% auto auto 1px;
  }
`;

const Title = styled.nav`
  height: 6rem;
  width: 460px;
  background-color: #14141c;
  

  @media (max-width: 1360px) {
    height: 6rem;
    width: 460px;
  }
  @media (max-width: 640px) {
    height: 5rem;
    width: 400px;
  }
  @media (max-width: 480px) {
    height: 5rem;
    width: 360px;
  }
  @media (max-width: 376px) {
    height: 4rem;
    width: 340px;
  }
`;

const Title2 = styled.h2`
  color: #fff;
  font-size: 28px;
  display: flex;
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center; 
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;

  @media (max-width: 1360px) {
    font-size: 25px;
  }
  @media (max-width: 640px) {
    font-size: 22px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
  }
  @media (max-width: 376px) {
    font-size: 22px;
    padding-left: 1rem;
    padding-right: 1rem;
  }  
`;








function Login() {
  const [setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);



  const getUsers = useCallback(async () => {
    try {
      const res = await axios.get("/apinova/login/");
      //const res = await axios.get("http://192.168.100.103:8831/login/");
      setUsers(res.data.sort((a, b) => (a.logon > b.logon ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Container>
        <Title>
          <Title2>
            <h2>DHO</h2>
            <img src={logo} style={{ width: '200px', height: 'auto' }} alt="Logo" />
          </Title2>
        </Title>
        <Form onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} />
      </Container>
      <ToastContainer autoClose={1500} position={toast.POSITION.BOTTOM_LEFT} style={{ width: '400px', fontSize: '22px' }} />
      <GlobalStyle />
    </>
  );
}

export default Login;
