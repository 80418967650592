import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { writeFile, utils } from 'xlsx';
import { saveAs } from 'file-saver';
import './styles.css'
import { BiSearchAlt } from "react-icons/bi";




const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Ajuste conforme necessário para o espaçamento desejado */
`;

const Table = styled.table`
  max-width: 1300px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #ccc;
  border-radius: 1px;
  margin: 20px auto;


  @media (max-width: 1024px) {
    width: 965px;
    padding: 15px;
    margin: 10px 20px;
  }
  @media (max-width: 820px) {
    width: 780px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    width: 580px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 500px) {
    width: 480px;
    padding: 5px;
    margin: 10px 20px;
  }
  @media (max-width: 420px) {
    width: 370px;
    padding: 5px;
    margin: 5px 20px 10px;
    
  }

  
`;


export const Thead = styled.thead``;


export const Tbody = styled.tbody`
  padding-bottom: 25px; 

`;


export const Tr = styled.tr`
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 50px;
`;



export const Th = styled.th`
  color: #4f4f4f;
  text-align: start;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;
export const Th2 = styled.th`
  color: #4f4f4f;
  text-align: center;
  border-bottom: inset;
  padding-bottom: 5px;

  @media (max-width: 640px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 500px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
  @media (max-width: 420px) {
    ${(props) => props.onlyWeb && "display: none;"}
  }
`;


export const Td = styled.td`
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  width: ${(props) => (props.width ? props.width : "auto")};
 
  @media (max-width: 640px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 500px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
  @media (max-width: 420px) {
    padding-left: 10px;
    ${(props) => props.onlyWeb && "display: none"}
  }
`;


export const Th3 = styled.th`
  @media (max-width: 2640px) {
    ${(props) => props.onlyWeb2 && "display: none;"}
  }

`;


export const Td3 = styled.td`
  @media (max-width: 2640px) {
    ${(props) => props.onlyWeb2 && "display: none;"}
  }
`;


export const Td1 = styled.td`
  padding-top: 15px;
  text-align: start;
  text-overflow: ellipsis;
`;
export const Td4 = styled.td`
  width: 10%;
  padding-top: 15px;
  text-align: center;

`;


export const Td2 = styled.td`
  padding-top: 15px;
  text-align: center;
`;


const Input = styled.input`
  width: 345px;
  padding: 0 10px;
  border: 1px solid #bbb;
  border-radius: 1px;
  height: 37px;

  @media (max-width: 1024px) {
    width: 300px;
    height: 30px;
  }
  @media (max-width: 820px) {
    width: 300px;
    height: 35px;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 640px) {
    width: 300px;
    height: 35px;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 35px;
  }
`;

const Button = styled.button`
  font-size: 17px;
  font-weight: bold;
  height: 37px;

  padding: 0px 20px;
  background-color: #03A64A;
  cursor: pointer;
  border: 1px solid #97A6A0;
  color: #fff;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`;


const Grid = ({ users, avaliacao, modulo, pergunta, resposta, usuario }) => {
  //console.log('Valor de users:', users);
  //const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isLoading, setIsLoading] = useState(true);
  const [insertedId, setInsertedId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');



  const urlParams = new URLSearchParams(window.location.search);
  const queryString = urlParams.get('login');
  let logon = null;
  let insertedId2 = null;
  //console.log("insertedId:", insertedId);
  if (queryString) {
    const loginValue = queryString.split('&')[0];
    const loginDecoded = decodeURIComponent(loginValue);
    logon = atob(loginDecoded);

    // Agora, vamos verificar se existe o parâmetro "insertedId" na URL
    if (window.location.search.includes('insertedId')) {
      const insertedIdValue = window.location.search.split('insertedId:')[1];
      insertedId = parseInt(insertedIdValue, 10);
    }

    
  } else {
    
    window.location.href = '/login';
  }


  const loginEncoded = encodeURIComponent(btoa(logon));
  //console.log(loginEncoded);





  useEffect(() => {
    if (insertedId !== null) {
      setSearchTerm(insertedId);
      handleSearchButtonClick();
      //console.log('useEffect - insertedId:', insertedId);
      //console.log('useEffect - searchTerm:', searchTerm);
    }
  }, [insertedId]);

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
   //console.log('handleSearchInputChange - searchTerm:', searchTerm);
  };



  const handleSearchButtonClick = () => {
    //console.log('Filtrando pelo valor:', searchTerm);
    const filteredUsers = users.filter((item) =>
      Object.values(item).some((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            return String(value).toLowerCase().includes(searchTerm.toLowerCase());
          }
        }
        return false;
      })
    );

    //console.log('filteredUsers:', filteredUsers); // Adicione esta linha

    setFilteredUsers(filteredUsers);
    setIsLoading(false);
  };


  const exportToExcel = () => {
    // Crie um novo livro de trabalho (workbook)
    const wb = utils.book_new();

    // Crie uma nova planilha (worksheet)
    const ws = utils.json_to_sheet(filteredUsers);

    // Adicione a planilha ao livro de trabalho
    utils.book_append_sheet(wb, ws, 'Planilha 1');

    // Gere o arquivo Excel
    const excelBuffer = writeFile(wb, 'dadosExportados.xlsx');

    // Salve o arquivo ou abra-o para download
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'dadosExportados.xlsx');
  };



  const tableCellStyle = {
    border: '1px solid #D9D9D9',
    padding: '8px',
  };



  const tableCellStyleModulo = {
    marginTop: '10px',
  };

  //console.log("Resposta", resposta)
  const descricaoAvaliacao = (id) => {
    const itemEncontrado = avaliacao.find((item) => item.id_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoModulo = (id) => {
    const itemEncontrado = modulo.find((item) => item.id_item_avaliacao === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoPergunta = (id) => {
    const itemEncontrado = pergunta.find((item) => item.id_item_pergunta === id);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoResposta = (id) => {
    const faixaId = parseInt(id);
    const itemEncontrado = resposta.find((item) => item.id_faixa === faixaId);
    //console.log('Item encontrado:', itemEncontrado);
    return itemEncontrado ? itemEncontrado.descricao : '';
  };

  const descricaoUsuario = (logon_avaliador) => {
    const avaliador = usuario.find((item) => item.logon === logon_avaliador);


    const nomeAvaliador = avaliador ? avaliador.name : '';


    return { nomeAvaliador };
  };

  const descricaoUsuario2 = (logon_avaliado) => {


    const avaliado = usuario.find((item) => item.logon === logon_avaliado);


    const nomeAvaliado = avaliado ? avaliado.name : '';

    return {  nomeAvaliado };
  };

  return (

    <div>
      <Div>
        <InputGroup>
          <Input
            type="text"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={handleSearchInputChange}
          />
          <Button onClick={() => setInsertedId(searchTerm)}>
            <BiSearchAlt />Pesquisar
          </Button>
          {/*<Button onClick={exportToExcel}>Exportar para Excel</Button>*/}
        </InputGroup>
      </Div>
      {isLoading ? (
        <p></p>
      ) : (
        <Table >
          <Tbody>
            {filteredUsers.reduce((acc, item, i, arr) => {
              const isFirstRow = i === 0 || arr[i - 1].id_aplicacaoavaliacao !== item.id_aplicacaoavaliacao;

              if (isFirstRow) {
                acc.push(
                  <React.Fragment key={i}>
                    <Tr >
                      <Td width="5%" style={tableCellStyle}>{item.id_aplicacaoavaliacao}</Td>
                      <Td colSpan={7} width="95%" style={{...tableCellStyle, textAlign: 'center'}}>
                        {descricaoAvaliacao(item.id_avaliacao)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td></Td>
                      <Td colSpan={1}  style={tableCellStyle}>
                        Avaliador: {item.logon_avaliador} - {descricaoUsuario(item.logon_avaliador).nomeAvaliador}    
                      </Td>
                      <Td colSpan={2}  style={tableCellStyle}>
                         Avaliado: {item.logon_avaliado} - {descricaoUsuario2(item.logon_avaliado).nomeAvaliado}
                      </Td>
                      

                    </Tr>
                  </React.Fragment>
                );
              }

              acc.push(
                <React.Fragment >
                  <Tr>
                    <Td></Td>
                    <Td style={tableCellStyle}> {descricaoModulo(item.id_item_avaliacao)}</Td>
                    <Td style={tableCellStyle}> {descricaoPergunta(item.id_item_pergunta)}</Td>
                    <Td width="10%" style={{...tableCellStyle, textAlign: 'center'}}>{descricaoResposta(item.id_faixa)}</Td> 
                  </Tr>
                </React.Fragment>
              );
              return acc;
            }, [])}
          </Tbody>
        </Table >
      )}
         </div >
  );
};

export default Grid;
