import { createGlobalStyle } from "styled-components";



const Global = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, Helvetica, Arial, sans-serif;
  }
  
  
  body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    margin: 0; 
    background-color: #e8eaeb;
  }
  

`;

export default Global;